import { useQuery } from "react-query";

import useApiClient from "api/useApiClient";

export const getTreatmentActivityLogsQueryKey = () => ["getTreatmentActivityLogs"] as const;

const useGetTreatmentActivityLogs = () => {
  const client = useApiClient();

  return useQuery(getTreatmentActivityLogsQueryKey(), () => client.getTreatmentActivityLogs());
};

export default useGetTreatmentActivityLogs;
