import type React from "react";

import { format, startOfMonth } from "date-fns";
import invariant from "ts-invariant";

import useGetEnrollmentBonuses from "api/hooks/useGetEnrollmentBonuses";
import { useProfileContext } from "contexts/ProfileContext";

import { EnrolledPatients } from "./EnrolledPatients";

export const EarningsWidgetSE: React.VFC = () => {
  const { profile } = useProfileContext();

  invariant(profile, "Profile should be defined");

  const { data } = useGetEnrollmentBonuses({
    therapist_id: profile.id,
    start_date: format(startOfMonth(new Date()), "yyyy-MM-dd"),
  });

  return (
    <div>
      <EnrolledPatients enrollments={data?.enrollments} enrolledPatientsGoal={15} />
    </div>
  );
};
