import type React from "react";
import { useState } from "react";

import { Cancel } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CheckIcon } from "assets";

interface Props {
  onSubmit: (value: string) => void;
}

const NewQuickReplyForm: React.VFC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleOnSubmit();
    }
  };

  const handleOnSubmit = () => {
    onSubmit(value);
    setValue("");
  };

  return (
    <Container>
      <CustomTextArea data-value={value}>
        <textarea
          data-testid="NewQuickReplyForm-input"
          value={value}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          placeholder={t("buttons.add")}
          rows={1}
        />

        {value && (
          <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
            <ClearInputButton id="clear-input-button" role="button" onClick={() => setValue("")}>
              <Cancel />
            </ClearInputButton>
          </>
        )}
      </CustomTextArea>

      {value && (
        <SaveQuickReply data-testid="NewQuickReplyForm-save-button" onClick={handleOnSubmit}>
          <CheckIcon />
        </SaveQuickReply>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
`;

const CustomTextArea = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-column-gap: 4px;
  border: 1px solid #7f94a9;
  border-radius: 24px;

  &:after {
    content: attr(data-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
  }

  textarea {
    all: unset;
    resize: none;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.redesign.db60};

    &::placeholder {
      color: ${props => props.theme.colors.greys.pinkish};
      user-select: none;
    }
  }

  &:after,
  textarea {
    grid-area: 1 / 1 / 2 / 2;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    overflow: hidden;
    padding: 10px 0 10px 11px;
  }
`;

const ClearInputButton = styled.div`
  grid-area: 1 / 2;
  margin-top: 6px;
  margin-right: 6px;
  cursor: pointer;

  svg {
    fill: rgba(0, 0, 0, 0.26);
  }

  &:hover {
    svg {
      fill: rgba(0, 0, 0, 0.4);
    }
  }
`;

const SaveQuickReply = styled.div`
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  cursor: pointer;

  svg {
    circle {
      fill: ${({ theme }) => theme.colors.redesign.b100};
    }
  }
`;

export default NewQuickReplyForm;
