import type React from "react";
import { useState } from "react";

import type { SelectProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CloseIcon } from "assets";
import { PrimaryButton } from "shared/atoms/Button";
import IconButton from "shared/atoms/IconButton";
import { MUIDropdown } from "shared/atoms/inputs";
import Popup from "shared/atoms/Popup";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";

interface Props {
  setOpen: (open: boolean) => void;
  patientId?: number;
}
const DropInCallFeedbackPopup: React.VFC<Props> = ({ setOpen, patientId }) => {
  const { t } = useTranslation();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [answer, setAnswer] = useState<unknown>(null);
  const options = [
    { label: t("drop_in_calls.feedback_popup.option_1"), value: "1", disabled: true },
    { label: t("drop_in_calls.feedback_popup.option_2"), value: "2" },
    { label: t("drop_in_calls.feedback_popup.option_3"), value: "3" },
    { label: t("drop_in_calls.feedback_popup.option_4"), value: "4" },
    { label: t("drop_in_calls.feedback_popup.option_5"), value: "5" },
  ];
  const onChange = (event: Parameters<NonNullable<SelectProps["onChange"]>>[0]) => {
    const index = options.findIndex(obj => obj.value === event.target.value);
    if (index > 0) {
      setAnswer(event.target.value);
      setButtonDisabled(false);
    }
  };

  const onSubmit = () => {
    if (answer) {
      AnalyticsService.track(AnalyticsEvents.DROP_IN_CALLS.FEEDBACK_ANSWER, {
        answer,
        patient: patientId,
      });
      setOpen(false);
    }
  };
  return (
    <Container>
      <Popup onClickOutside={() => undefined} fullBgOpacity>
        <SubContainer>
          <StyledIconButton label="" onClick={() => setOpen(false)}>
            <CloseIcon />
          </StyledIconButton>

          <Header>{t("drop_in_calls.feedback_popup.title")}</Header>
          <Body>{t("drop_in_calls.feedback_popup.body")}</Body>
          <MUIDropdown in_form={false} name="dropincall-feedback-dropdown" options={options} onChange={onChange} />
          <StyledPrimaryButton disabled={buttonDisabled} onClick={onSubmit}>
            {t("drop_in_calls.end_call")}
          </StyledPrimaryButton>
        </SubContainer>
      </Popup>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  z-index: ${props => props.theme.zIndex.overlay};
  width: 100%;
  height: 100%;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.S_25};
`;

const Body = styled.div`
  ${props => props.theme.font.body2};
  margin: ${props => props.theme.spacing.S_20} ${props => props.theme.spacing.S_40} ${props => props.theme.spacing.S_20}
    ${props => props.theme.spacing.S_40};
  text-align: center;
  white-space: pre-wrap;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: ${props => props.theme.colors.redesign.db80};
`;

const Header = styled.div`
  ${props => props.theme.font.header1};
  text-align: center;
  ${props => props.theme.belowBreakpoint} {
    margin-top: ${props => props.theme.spacing.S_10};
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 30%;
  margin: 63px auto 25px auto;
`;

const StyledIconButton = styled(IconButton)`
  align-self: flex-end;
`;

export default DropInCallFeedbackPopup;
