import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

import type { EarningsUsTherapist, EarningsUsTherapistArgs } from "api/schemas/EarningsUsTherapist";
import useApiClient from "api/useApiClient";

export const queryKeyPrefix = "getEnrollmentBonuses";
type QueryKey = [typeof queryKeyPrefix, number, string];
export const getEnrollmentBonusesQueryKey = (args: EarningsUsTherapistArgs): QueryKey => [
  queryKeyPrefix,
  args.therapist_id,
  args.start_date,
];

const useGetEnrollmentBonuses = (
  args: EarningsUsTherapistArgs,
  options?: UseQueryOptions<EarningsUsTherapist, Error, EarningsUsTherapist, QueryKey>
) => {
  const client = useApiClient();

  return useQuery(getEnrollmentBonusesQueryKey(args), () => client.getEnrollmentBonuses(args), options);
};

export default useGetEnrollmentBonuses;
