import type React from "react";
import { useEffect, useState } from "react";

import type { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQueryClient } from "react-query";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import { AxiosInstance } from "api";
import { getPatientUnreadCommentsCountQueryKey } from "api/hooks/useGetPatientUnreadCommentsCountQuery";
import useGetSmartQuickReply from "api/hooks/useGetSmartCommentReplies";
import { useProfileContext } from "contexts/ProfileContext";
import markAsUnviewed from "routes/patients/queries/markAsUnviewed";
import markAsViewed from "routes/patients/queries/markAsViewed";
import { getTextLessonPart, handleType, isTranslatedActivity } from "routes/patients/utils/activityHelpers";
import { CheckBox } from "shared/atoms/inputs";
import { Notification } from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import getPaginationHeaders from "utils/pagination/getPaginationHeaders";
import type { ResponseHeaders } from "utils/pagination/getPaginationHeaders";

import getComments from "../../queries/getComments";
import Comment from "../components/Comments/Comment";

export interface Comment {
  id: number;
  reply_to_comment?: string;
  start_date: string;
  activity_name: string;
  activity_type: string;
  comment: string;
  exercise_max_level: number;
  exercise_current_level: number;
  translations: { library_id: number; title: string };
  completed_on: string;
  viewed_by_therapist_at: string | null;
}

interface State {
  comments: Comment[];
  error: boolean;
  finishedInitialRequest: boolean;
  hasMore: boolean;
  nextUrl: string;
}

const LIMIT = 10;

const Comments: React.VFC = () => {
  const { t } = useTranslation();
  const match = useMatch("*");
  const regex = /patients\/([\d]+)\/?/m;
  const queryClient = useQueryClient();
  const [unreadOnly, setUnreadOnly] = useState(false);
  const [resetComments, setResetComments] = useState(false);

  const patientId = match?.pathname.match(regex)?.[1];
  const [{ error, comments, nextUrl, hasMore, finishedInitialRequest }, setState] = useState<State>({
    comments: [],
    error: false,
    finishedInitialRequest: false,
    hasMore: true,
    nextUrl: "",
  });

  const { profile } = useProfileContext();
  invariant(profile);

  const isSmartRepliesEnabled = profile?.market === "SE";
  const { data: smartReplies } = useGetSmartQuickReply(
    { therapistId: profile?.id, patientId: parseInt(patientId as string, 10) },
    { enabled: isSmartRepliesEnabled }
  );

  useEffect(() => {
    if (match) AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.COMMENTS, match.pathname);
  }, [match?.pathname]);

  const setError = () => setState(prev => ({ ...prev, error: true }));

  const setComments = (response: AxiosResponse<Comment[]>) => {
    const { data, headers } = response;
    const { next, totalCount } = getPaginationHeaders(headers as ResponseHeaders);
    setState(prev => {
      let newComments;
      newComments = [...prev.comments, ...data];
      if (resetComments) {
        newComments = [...data];
        setResetComments(false);
      }
      const isThereMoreComments = () => {
        if (totalCount) {
          if (unreadOnly) {
            return totalCount > 0;
          }
          return newComments.length < totalCount;
        }
        return false;
      };

      return {
        ...prev,
        comments: newComments,
        nextUrl: next || prev.nextUrl,
        hasMore: isThereMoreComments(),
        finishedInitialRequest: true,
      };
    });
  };

  useEffect(() => {
    const initialFetch = async () => {
      try {
        const response = await getComments(patientId as string, { limit: LIMIT }, unreadOnly as boolean);
        if (response) {
          setComments(response);
          markCommentsAsViewed(response.data);
        }
      } catch (e) {
        setState(prev => ({ ...prev, finishedInitialRequest: true }));
        setError();
        // TODO: add error logging
      }
    };
    initialFetch();
    setState(prev => ({ ...prev, finishedInitialRequest: false }));
  }, [unreadOnly]);

  const fetchEarlier = async () => {
    try {
      const response = await AxiosInstance.get(nextUrl);
      if (response) {
        setComments(response);
        markCommentsAsViewed(response.data);
      }
    } catch (e) {
      setError();
      // TODO: add error logging
    }
  };

  const handleName = (comment: Comment) => {
    const { activity_name: activityName, activity_type: activityType, translations } = comment;
    if (activityName === "text_lesson") {
      return `${translations?.title} ${t("activities.part", { number: getTextLessonPart(activityType) })}`;
    }
    // FIXME: type translation
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return isTranslatedActivity(activityName) ? `${translations?.title}` : t(`activities.${activityType}`);
  };

  const markCommentsAsViewed = (initialComments?: Comment[]) => {
    const unviewedCommentIds = (initialComments || comments)
      .filter(comment => comment.viewed_by_therapist_at === null)
      .map(comment => comment.id);

    if (patientId && unviewedCommentIds.length !== 0) {
      markAsViewed(patientId, unviewedCommentIds).then(() => {
        queryClient.invalidateQueries(getPatientUnreadCommentsCountQueryKey(Number(patientId)));
      });
    }
  };

  const markCommentAsUnviewed = (id: number) => {
    if (patientId) {
      markAsUnviewed(patientId, [id]).then(res => {
        setState(prevState => {
          const updatedComments = prevState.comments.map(comment => {
            if (comment.id === res.data.activity_ids?.[0]) {
              return { ...comment, viewed_by_therapist_at: null };
            }
            return comment;
          });

          return { ...prevState, comments: updatedComments };
        });

        queryClient.invalidateQueries(getPatientUnreadCommentsCountQueryKey(Number(patientId)));
      });
    }
  };

  const getSmartReply = (commentId: number): string | null | undefined => {
    if (smartReplies?.comments && smartReplies.comments.length > 0) {
      return smartReplies.comments.find(reply => reply.user_activity_id === commentId)?.body;
    }
    return null;
  };

  const renderComments = () => {
    return (
      <>
        {comments.map(comment => (
          <Comment
            key={`${comment.id}_${comment.start_date}`}
            id={comment.id}
            completedOn={comment.completed_on}
            reply={comment.reply_to_comment}
            activity={handleType(comment.activity_name)}
            // FIXME: type translation
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            activityName={handleName(comment)}
            levels={comment.exercise_max_level}
            currentLevel={comment.completed_on ? comment.exercise_current_level : 0}
            comment={comment.comment}
            viewed={
              comment.viewed_by_therapist_at !== null ||
              new Date(comment.completed_on) < new Date(comment.viewed_by_therapist_at as unknown as string)
            }
            markCommentAsUnviewed={() => markCommentAsUnviewed(comment.id)}
            smartReply={getSmartReply(comment.id)}
          />
        ))}
      </>
    );
  };

  const loader = (
    <CenterWrapper>
      <Spinner dataTestId="spinner" small />
    </CenterWrapper>
  );

  const errorComponent = <Notification variant="danger">{t("errors.generic")}</Notification>;

  const renderLoaderOrError = () => {
    if (!finishedInitialRequest) return loader;
    if (error) return errorComponent;
    return false;
  };

  return (
    <Container>
      <StyledCheckBox
        onChange={() => {
          setUnreadOnly(prevState => !prevState);
          setResetComments(true);
        }}
        label={t("patients.comments.only_unread_comments")}
        name="unread-comments"
        checked={unreadOnly}
      />
      {renderLoaderOrError() || (
        <>
          <InfiniteScroll
            dataLength={comments.length}
            next={fetchEarlier}
            style={{ display: "flex", flexDirection: "column", overflow: "visible" }}
            hasMore={hasMore}
            loader={loader}
            scrollableTarget="comments-container"
          >
            {renderComments()}
            {comments.length === 0 && <NoCommentsLabel>{t("patients.comments.no_comments")}</NoCommentsLabel>}
          </InfiniteScroll>
        </>
      )}
    </Container>
  );
};

export default Comments;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 620px;
  box-sizing: border-box;
  margin: auto;
  padding: ${props => props.theme.spacing.S_20} ${props => props.theme.spacing.S_15};
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.greys.dark};
  ${props => props.theme.font.header5}
`;

const CenterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoCommentsLabel = styled.span`
  margin-left: 16px;
`;

const StyledCheckBox = styled(CheckBox)`
  margin-bottom: 20px;
`;
