import { faCircleCheck, faDoNotEnter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";

import type { State } from "api/schemas/NewAppointment";

interface Props {
  state: State;
  largeMargin?: boolean;
}

export const AppointmentStateIcon: React.VFC<Props> = ({ state, largeMargin }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  switch (state) {
    case "completed":
      return (
        <Tooltip placement="top" title={t("booking.event_details.completed")}>
          <span style={{ display: "inline-flex" }}>
            <StyledFontAwesomeIcon icon={faCircleCheck} color={theme.colors.redesign.g100} $largeMargin={largeMargin} />
          </span>
        </Tooltip>
      );
    case "no_show":
      return (
        <Tooltip placement="top" title={t("booking.event_details.no_show")}>
          <span style={{ display: "inline-flex" }}>
            <StyledFontAwesomeIcon icon={faDoNotEnter} color={theme.colors.redesign.r100} $largeMargin={largeMargin} />
          </span>
        </Tooltip>
      );
    default:
      return null;
  }
};

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<{ $largeMargin?: boolean }>`
  margin-right: ${({ $largeMargin }) => ($largeMargin ? "14px" : "4px")};
`;
