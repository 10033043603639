import type React from "react";
import { useContext, useEffect, useState } from "react";

import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import invariant from "ts-invariant";

import useGetCareEvent from "api/hooks/useGetCareEvent";
import useGetCareEventsSuggested from "api/hooks/useGetCareEventsSuggested";
import useGetPatients from "api/hooks/useGetPatients";
import { useGetPTCareEventsQuery } from "api/hooks/useGetPTCareEventsQuery";
import { usePostCareEventSuggested } from "api/hooks/usePostCareEventSuggested";
import type { CareEvent, PatientStateTypes } from "api/models/CareEvent";
import type { PremiumType } from "api/models/PatientProfile";
import { SmallBlueClock } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import {
  ButtonContainer,
  CareEventContainer,
  PopupContent,
  StyledTypeWrapper,
} from "routes/admin-billing/views/AdminBillingView";
import { Header } from "routes/calendar/components/EventDetails/styles";
import DynamicMedicalNoteForm from "routes/patients/PatientProfile/components/PatientHeader/SignNotesTable/components/DynamicMedicalNoteForm";
import MedicalNoteLabel from "routes/patients/PatientProfile/components/PatientHeader/SignNotesTable/components/MedicalNoteLabel";
import { removeNote } from "routes/patients/queries/notesApiHandler";
import { BaseButton } from "shared/atoms/BaseButton";
import { Notification } from "shared/atoms/Notification";
import Pagination from "shared/atoms/Pagination";
import Popup from "shared/atoms/Popup";
import PremiumIcon from "shared/atoms/PremiumIcon";
import {
  Table,
  TableCell,
  TableFooter,
  TableHeader,
  TableMissingContent,
  TableRow,
  TableSort,
} from "shared/molecules/Table";
import { MarketTypes } from "types";
import { CurrentPatientContext } from "utils/contexts";
import useLocalizedDate from "utils/date";
import { useWindowSize } from "utils/hooks";
import { isUserMarket } from "utils/profile/profileHelper";
import { getStorageValue, setStorageValue } from "utils/storage";

import { CommunicationGroupIcon } from "./CommunicationGroupIcon";
import {
  AfterNameIcon,
  Closed,
  DateContainer,
  DateWrapper,
  Name,
  NameWrapper,
  NameWrapperInner,
  Type,
  TypeWrapper,
} from "./styles";
import typeSort from "./utils/typeSort";

type Note = {
  id: number;
  communication_group: string | null;
  patient: {
    id: number;
    name: string;
    deleted: boolean;
    state?: PatientStateTypes;
    premium_type: PremiumType;
  };
  type: string;
  date?: Date;
  therapist_assignment_role?: string | null;
  week_number?: number;
  label?: string;
  patient_id?: number;
  message_interaction_id?: number;
  rtm_care_event_log_id?: number;
  user_course_week_id?: number;
  cpt_codes?: string[];
  suggested?: boolean;
};

const MedicalNotesTable: React.VFC = () => {
  const { t } = useTranslation();
  const { parseISO, format } = useLocalizedDate();
  const theme = useContext(ThemeContext);
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { profile } = useProfileContext();
  const { patient, setPatientId } = useContext(CurrentPatientContext);
  const createCareEventSuggested = usePostCareEventSuggested();
  invariant(profile);
  const isSE = isUserMarket(MarketTypes.SE, profile);

  const columns =
    width > parseInt(theme.breakpoint, 10)
      ? [
          t("dashboard.tables.notes.labels.patient"),
          t("dashboard.tables.notes.labels.type"),
          t("dashboard.tables.notes.labels.date"),
          t("dashboard.tables.notes.labels.sign_note"),
          t("dashboard.tables.notes.labels.discard"),
        ]
      : [t("dashboard.tables.notes.labels.patient"), t("dashboard.tables.notes.labels.date")];

  const [sort, setSort] = useState({
    selectedColumn: columns[2],
    descending: false,
  });
  const [offset, setOffset] = useState(0);
  const [notes, setNotes] = useState<Array<Note>>([]);

  const { id: userId } = profile;
  const { data, isLoading, error, refetch: refetchPTCareEvents } = useGetPTCareEventsQuery({ userId });

  const { data: patients } = useGetPatients(profile.id);
  const patientIDs = patients?.map(p => p.id) ?? [];
  const { data: careEventSuggested, refetch: refetchSuggested } = useGetCareEventsSuggested(patientIDs, {
    enabled: patientIDs.length > 0,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedCareEvent, setSelectedCareEvent] = useState<any | null>(null);
  const [showCareEvent, setShowCareEvent] = useState(false);
  const { data: careEvent } = useGetCareEvent(selectedCareEvent ? selectedCareEvent?.id : 0, {
    enabled: Boolean(selectedCareEvent),
  });
  const [showMarkAsDiscarded, setShowMarkAsDiscarded] = useState(false);
  const [customError, setCustomError] = useState<string | null>(null);

  const LIMIT = 10;

  const compare = (a: CareEvent, b: CareEvent) => {
    let x: string | number | Date = "";
    let y: string | number | Date = "";
    switch (sort.selectedColumn) {
      case t("dashboard.tables.notes.labels.patient"):
        x = a.patient_name.toLowerCase();
        y = b.patient_name.toLowerCase();
        break;
      case t("dashboard.tables.notes.labels.type"):
        x = typeSort[a.label] ?? 99;
        y = typeSort[b.label] ?? 99;
        break;
      case t("dashboard.tables.notes.labels.date"):
        x = parseISO(a.start_time ?? "");
        y = parseISO(b.start_time ?? "");
        break;
      default:
        break;
    }
    if (x === y) {
      x = a.id;
      y = b.id;
    }
    if (sort.descending) {
      return y < x ? 1 : -1;
    }
    return x < y ? 1 : -1;
  };

  useEffect(() => {
    const storedMedicalNoteSorting = getStorageValue("medical-notes-sort");
    if (storedMedicalNoteSorting) {
      setSort(storedMedicalNoteSorting);
    }
  }, []);

  useEffect(() => {
    if (data) {
      const tempDate = new Date();

      const mappedCareEventSuggested = careEventSuggested?.map(ce => ({ ...ce, suggested: true }));
      // TODO: fix types
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const mergedNotes = data.concat(mappedCareEventSuggested ?? []);

      const filteredNotes = mergedNotes
        .sort(compare)
        .filter((_node: CareEvent, index: number) => index >= offset && index < offset + LIMIT)
        .map((node: CareEvent) => {
          return {
            id: node.id,
            communication_group: node.communication_group,
            patient: {
              id: node.patient_id,
              name: node.patient_name,
              deleted: node.patient_deleted,
              state: node.patient_state,
              premium_type: node.premium_type,
            },
            type: node.label.toUpperCase(),
            date: node?.start_time ? parseISO(node.start_time ?? "") : tempDate,
            therapist_assignment_role: node.therapist_assignment_role,
            week_number: node.week_number,
            suggested: node.suggested,
            cpt_codes: node.cpt_codes,
            message_interaction_id: node.message_interaction_id,
            rtm_care_event_log_id: node.rtm_care_event_log_id,
            user_course_week_id: node.user_course_week_id,
            label: node.label,
          };
        });
      setNotes(filteredNotes);
    }
  }, [careEventSuggested, data, sort, offset, parseISO]);

  useEffect(() => {
    if (careEvent) {
      setPatientId(careEvent.patient_id);
    } else {
      setPatientId(null);
    }
  }, [careEvent, setPatientId]);

  if (error || isLoading) {
    return (
      <Table>
        <thead>
          <TableHeader colSpan={columns.length} title={t("dashboard.tables.notes.title")} />
        </thead>
        <tbody>
          <TableMissingContent error={!!error} loading={isLoading} />
        </tbody>
        <tfoot />
      </Table>
    );
  }

  const hasNextPage = data ? data.length > offset + LIMIT : false;
  const hasPreviousPage = offset !== 0;
  const totalCount = data?.length || 0;

  const onSort = (clickedColumn: string) => {
    if (sort.selectedColumn === clickedColumn) {
      setSort({ selectedColumn: clickedColumn, descending: !sort.descending });
      setStorageValue("medical-notes-sort", { selectedColumn: clickedColumn, descending: !sort.descending });
    } else {
      setSort({ selectedColumn: clickedColumn, descending: true });
      setStorageValue("medical-notes-sort", { selectedColumn: clickedColumn, descending: true });
    }
    setOffset(0);
  };

  const createCareEvent = (note: Note, showDiscardCE: boolean) => {
    const { cpt_codes, label, message_interaction_id, rtm_care_event_log_id, user_course_week_id } = note;

    if (label && (message_interaction_id || rtm_care_event_log_id || user_course_week_id)) {
      createCareEventSuggested.mutateAsync(
        {
          data: {
            cpt_codes,
            label,
            message_interaction_id,
            rtm_care_event_log_id,
            user_course_week_id,
          },
          patientID: note.patient.id,
        },
        {
          onSuccess(careEventCreateData) {
            refetchPTCareEvents();
            refetchSuggested();
            setSelectedCareEvent({ id: careEventCreateData.id });
            if (showDiscardCE) {
              setShowMarkAsDiscarded(true);
            } else {
              setShowCareEvent(true);
            }
          },
        }
      );
    }
  };

  const discardFromDashboard = async () => {
    refetchPTCareEvents();
    refetchSuggested();
    setSelectedCareEvent(null);
  };

  const isActionable = (note: Note) => {
    if (isSE) {
      return note.label === "chat_hybrid" || note.label === "initial_hybrid";
    }
    return true;
  };

  return (
    <>
      <Table>
        <thead>
          <TableHeader colSpan={columns.length} title={t("dashboard.tables.notes.title")} notifications={totalCount} />
          {notes.length > 0 && (
            <TableSort columns={columns} sortBy={sort.selectedColumn} descending={sort.descending} onSort={onSort} />
          )}
        </thead>
        <tbody>
          {notes.length > 0 &&
            notes.map(note => {
              return (
                <TableRow
                  key={note.id}
                  onClick={() => {
                    navigate(`/patients/${note.patient.id}`, {
                      state: {
                        pathname,
                        text: "dashboard",
                        careEventID: note.id,
                      },
                    });
                  }}
                >
                  <NameWrapper dataTestId="medical-note">
                    <NameWrapperInner>
                      <PremiumIcon size="small" premiumType={note.patient.premium_type} margin="0 4px 0 0" />
                      <Name
                        $label_state={
                          note.patient.state === "treatment_ended" ||
                          note.patient.state === "discharged" ||
                          note.patient.state === "in_selfcare"
                        }
                      >
                        {note.patient.name}
                        {note.patient.deleted && (
                          <Closed data-testid="account-closed">{t("errors.account_closed")}</Closed>
                        )}
                        {!note.patient.deleted && note.patient.state === "treatment_ended" && (
                          <Closed data-testid="treatment-ended">{t("errors.account_treatment_ended")}</Closed>
                        )}
                        {!note.patient.deleted && note.patient.state === "discharged" && (
                          <Closed data-testid="discharged">{t("errors.account_discharged")}</Closed>
                        )}
                        {!note.patient.deleted && note.patient.state === "in_selfcare" && (
                          <Closed data-testid="in-selfcare">{t("errors.account_in_selfcare")}</Closed>
                        )}
                      </Name>
                      {note.therapist_assignment_role === "substitute_therapist" && (
                        <AfterNameIcon title={t("patients.tool_tip.substitute_therapist")}>
                          <SmallBlueClock />
                        </AfterNameIcon>
                      )}
                    </NameWrapperInner>
                  </NameWrapper>
                  <TypeWrapper dataTestId="medical-note-type">
                    <Type data-testid={note.type}>
                      {note.communication_group && (
                        <CommunicationGroupIcon communicationGroup={note.communication_group} />
                      )}
                      <MedicalNoteLabel weekNumber={note.week_number} careEventLabel={note.type} />
                    </Type>
                    {width < parseInt(theme.breakpoint, 10) && (
                      <DateContainer>{note?.date ? format(note.date, "P") : "missing"}</DateContainer>
                    )}
                  </TypeWrapper>
                  <DateWrapper dataTestId="medical-note-date">
                    <DateContainer>{note?.date ? format(note.date, "P") : "missing"}</DateContainer>
                  </DateWrapper>

                  {isActionable(note) && (
                    <>
                      <StyledTypeWrapper>
                        <ButtonContainer
                          onClick={e => {
                            e.stopPropagation();
                            if (note.suggested) {
                              createCareEvent(note, false);
                            } else {
                              setSelectedCareEvent({ id: note.id });
                              setShowCareEvent(true);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            color={theme.colors.redesign.g100}
                            style={{ height: "18px", width: "18px" }}
                          />
                        </ButtonContainer>
                      </StyledTypeWrapper>
                      <StyledTypeWrapper>
                        <ButtonContainer
                          onClick={e => {
                            e.stopPropagation();
                            if (note.suggested) {
                              createCareEvent(note, true);
                            } else {
                              setSelectedCareEvent({ id: note.id });
                              setShowMarkAsDiscarded(true);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            color={theme.colors.redesign.r100}
                            style={{ height: "18px", width: "18px" }}
                          />
                        </ButtonContainer>
                      </StyledTypeWrapper>
                    </>
                  )}
                </TableRow>
              );
            })}

          {notes.length <= 0 && (
            <TableRow hoverEnabled={false}>
              <TableCell>{t("dashboard.tables.notes.no_notes") as string}</TableCell>
            </TableRow>
          )}
        </tbody>
        <tfoot>
          {totalCount > LIMIT && (
            <TableFooter colSpan={columns.length} addTopBorder>
              <Pagination
                totalCount={totalCount}
                first={offset + 1}
                last={offset + LIMIT < totalCount ? offset + LIMIT : totalCount}
                pageInfo={{ hasNextPage, hasPreviousPage }}
                onPageChange={goTo => (goTo === "next" ? setOffset(offset + LIMIT) : setOffset(offset - LIMIT))}
              />
            </TableFooter>
          )}
        </tfoot>
      </Table>

      {careEvent && showCareEvent && patient && (
        <Popup
          fullBgOpacity
          onClickOutside={() => {
            setSelectedCareEvent(null);
            setShowCareEvent(false);
          }}
          showCloseButton
        >
          <CareEventContainer>
            <DynamicMedicalNoteForm
              careEvent={careEvent}
              animate
              open
              discardFromDashboard={discardFromDashboard}
              skipMerge
            />
          </CareEventContainer>
        </Popup>
      )}

      {showMarkAsDiscarded && selectedCareEvent && (
        <Popup fullBgOpacity onClickOutside={() => setShowMarkAsDiscarded(false)} showCloseButton>
          <PopupContent>
            <Header>{t("admin_billing.mark_as_discarded")}</Header>
            <BaseButton
              text={t("buttons.yes")}
              onClick={async () => {
                try {
                  await removeNote(selectedCareEvent.id);
                  refetchPTCareEvents();
                  refetchSuggested();
                  setShowMarkAsDiscarded(false);
                } catch {
                  setCustomError(t("errors.generic"));
                }
              }}
              disabled={isLoading}
              loading={isLoading}
              uppercase
              style={{ marginBottom: "20px" }}
            />
            <BaseButton
              text={t("buttons.cancel")}
              onClick={() => setShowMarkAsDiscarded(false)}
              disabled={isLoading}
              variant="tertiaryBlue"
              uppercase
            />
            {customError && (
              <Notification variant="danger" style={{ marginTop: "16px" }}>
                {customError}
              </Notification>
            )}
          </PopupContent>
        </Popup>
      )}
    </>
  );
};

export default MedicalNotesTable;
