import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  padding: 0 16px 48px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.div`
  display: flex;
  margin-bottom: 16px;

  & > svg {
    height: 24px;
    width: 24px;
    margin-right: 12px;
    margin-top: 13px;
  }
`;

export const Wrapper = styled.div`
  flex: 1;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    max-height: 52px;
    font-size: 16px;
    color: ${props => props.theme.colors.primary.base};
  }

  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: #d4dbe2;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
    border-color: #d4dbe2;
    border-width: 2px;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
  }
`;
