import type React from "react";
import { useEffect, useState } from "react";

import { Player } from "@lottiefiles/react-lottie-player";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { CountryData } from "react-phone-input-2";
import PhoneInput from "react-phone-input-2";
import styled from "styled-components";
import invariant from "ts-invariant";

import { CancelIcon, CheckIcon, EmailNewIcon, ProfileIconNew } from "assets";
import firstConfettiAnimation from "assets/animations/confetti.json";
import { useProfileContext } from "contexts/ProfileContext";
import type { FormData } from "routes/patients/queries/sendInvite";
import sendInvite from "routes/patients/queries/sendInvite";
import { BaseButton } from "shared/atoms/BaseButton";
import { ButtonsGroup } from "shared/atoms/ButtonsGroup";
import { MUIDropdown, MUITextInput } from "shared/atoms/inputs";
import { LanguageTypes } from "types";

import { PersonalInvitationLink } from "./PersonalInvitationLink";
import { RTMHealthPlans } from "./RTMHealthPlans";

import "react-phone-input-2/lib/plain.css";

const AVAILABLE_COUNTRY_CODES = ["fr", "se", "gb", "us"];

const InvitePatientForm: React.VFC = () => {
  const { profile } = useProfileContext();
  const { t } = useTranslation();
  const form = useForm<FormData>({ mode: "onChange", shouldUnregister: true });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = form;

  const [labelText, setLabelText] = useState({ isError: false, label: "" });
  const [showConfetti, setShowConfetti] = useState(false);
  const [inviteType, setInviteType] = useState("sms");
  const [dialCode, setDialCode] = useState("");
  const [showRTMHealthPlans, setShowRTMHealthPlans] = useState(false);

  invariant(profile);

  const onSubmit = handleSubmit(async ({ name, phone_number, phone_country_code, email, treatment_type }) => {
    sendInvite({
      email,
      name,
      language_code: profile.language_code || LanguageTypes.en,
      phone_number,
      ...(phone_number ? { phone_country_code } : {}),
      ...(treatment_type ? { treatment_type } : {}),
    })
      .then(() => {
        setShowConfetti(true);
        setLabelText({ isError: false, label: t("patients.invite_sent") });
        setTimeout(() => {
          setLabelText({ isError: false, label: "" });
        }, 2000);
        reset({ phone_number: dialCode });
      })
      .catch(err => {
        if (err.response?.status === 409) {
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setLabelText({ isError: true, label: t(`errors.invite_already_exists_${inviteType}`) });
        } else {
          setLabelText({ isError: true, label: err.response?.data?.errors?.join("\n") ?? t("errors.generic") });
        }
        setTimeout(() => setLabelText({ isError: false, label: "" }), 3000);
      });
  });

  const defaultCountryCode = profile.market?.toLocaleLowerCase();

  // TEMPORARY SOLUTION
  // Remove the UK condition when Braze campaign is live in UK
  // Exclude SMS and LINK invites for "GB" market
  const isUK = profile.market === "GB";
  const isUS = profile.market === "US";
  useEffect(() => {
    if (isUK) {
      setInviteType("email");
    }
  }, [profile]);

  const getTreatmentTypeOptions = () => {
    if (isUS) {
      return [
        { label: t("invite.treatment_type_options.rtm"), value: "rtm" },
        { label: t("invite.treatment_type_options.non_rtm"), value: "non_rtm" },
      ];
    }

    return [
      { label: t("invite.treatment_type_options.hybrid"), value: "hybrid" },
      { label: t("invite.treatment_type_options.digital"), value: "digital" },
    ];
  };

  return (
    <>
      <Text>{t("invite.text")}</Text>
      <FormProvider {...form}>
        <FormContainer onSubmit={onSubmit} data-testid="invite-patient-form">
          {isUK && <DummyDiv />}
          {!isUK && (
            <ButtonsGroup
              group={[
                { value: "sms", label: t("form.sms") },
                { value: "email", label: t("form.email") },
                { value: "link", label: t("form.link") },
              ]}
              active={inviteType}
              setActive={setInviteType}
            />
          )}
          {inviteType === "link" ? (
            <PersonalInvitationLink />
          ) : (
            <>
              <StyledMUITextInput
                name="name"
                placeholder={t("form.name")}
                startAdornment={<ProfileIconNew style={{ width: "16px" }} />}
                error={errors?.name && t("errors.string.empty")}
                required
              />

              {inviteType === "sms" && (
                <>
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <StyledPhoneInput
                        disableCountryGuess
                        disableDropdown
                        countryCodeEditable={false}
                        country={defaultCountryCode}
                        onlyCountries={AVAILABLE_COUNTRY_CODES}
                        inputProps={{
                          name: "phone_number",
                          required: true,
                        }}
                        onChange={(inputValue, data: CountryData, event) => {
                          event.stopPropagation();
                          setDialCode(`+${data.dialCode}`);
                          setValue("phone_country_code", data.countryCode.toLocaleUpperCase());
                          onChange(inputValue);
                        }}
                        value={value}
                        placeholder={t("form.phone_number")}
                      />
                    )}
                  />
                </>
              )}

              {inviteType === "email" && (
                <StyledMUITextInput
                  name="email"
                  type="email"
                  placeholder={t("form.email")}
                  startAdornment={<EmailNewIcon style={{ width: "16px" }} />}
                  error={errors?.email && t("errors.string.empty")}
                  required
                />
              )}

              {(isUS || profile?.therapist_profile?.feature_flags?.includes("HYBRID_TREATMENT_ENABLED")) && (
                <div>
                  <StyledMUIDropdown
                    name="treatment_type"
                    label={t("invite.treatment_type")}
                    options={getTreatmentTypeOptions()}
                    error={errors?.treatment_type && t("errors.string.empty")}
                    required
                  />

                  {isUS && (
                    <>
                      <BaseButton
                        text={t("invite.rtm_health_plans.button")}
                        onClick={() => setShowRTMHealthPlans(true)}
                        variant="tertiaryBlue"
                        style={{ marginBottom: "49px" }}
                      />
                      {showRTMHealthPlans && <RTMHealthPlans onClose={() => setShowRTMHealthPlans(false)} />}
                    </>
                  )}
                </div>
              )}

              {labelText.label ? (
                <LabelWrapper data-testid={labelText.isError ? "invite-error" : "invite-success"}>
                  {labelText.isError ? <CancelIcon /> : <Icon />}
                  <TextLabel>{labelText.label}</TextLabel>
                </LabelWrapper>
              ) : (
                <BaseButton type="submit" text={t("invite.submit")} style={{ marginTop: "8px" }} uppercase />
              )}
            </>
          )}

          {showConfetti && (
            <>
              <Player
                autoplay
                data-testid="first-confetti-animation"
                src={firstConfettiAnimation}
                onEvent={event => {
                  if (event === "complete") {
                    setShowConfetti(false);
                  }
                }}
                style={{ position: "absolute", left: 0, right: 0, bottom: 0 }}
              />
            </>
          )}
        </FormContainer>
      </FormProvider>
    </>
  );
};

const FormContainer = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;

  ${props => props.theme.aboveBreakpoint} {
    width: 360px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
`;

const StyledMUITextInput = styled(MUITextInput)`
  margin-bottom: 12px;
`;

const StyledMUIDropdown = styled(MUIDropdown)`
  margin-bottom: 8px;
`;

export const StyledPhoneInput = styled(PhoneInput)`
  margin-bottom: 12px;

  .form-control {
    ${props => props.theme.font.input.field}
    height: 49px;
    width: 100%;
    padding-left: 34px;

    color: ${props => props.theme.colors.primary.base};
    border-radius: ${props => props.theme.borderRadius.basic};
    border-color: ${props => props.theme.colors.redesign.db30};
    border-width: 2px;

    &:focus {
      border-color: ${props => props.theme.colors.redesign.b60};
    }

    &::placeholder {
      color: ${props => props.theme.colors.primary.base};
      opacity: 0.4;
    }
  }

  .flag-dropdown {
    border: none;
    pointer-events: none;
  }

  .flag-dropdown.open {
    background: none;

    .selected-flag {
      background: none;
    }
  }

  .selected-flag {
    padding: 0 0 0 12px;
    background: none;

    &:hover {
      background: none;
    }
  }
`;

const LabelWrapper = styled.div`
  margin-top: ${props => props.theme.spacing.S_20};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 14px;
`;

const TextLabel = styled.span`
  ${props => props.theme.font.link1};
  color: ${props => props.theme.colors.greys.dark};
  margin-left: ${props => props.theme.spacing.S_5};
  display: inline-block;
`;

const Icon = styled(CheckIcon)`
  width: 18px;
  height: 18px;
`;

const DummyDiv = styled.div`
  margin: 12px 0;
`;

export default InvitePatientForm;
