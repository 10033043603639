import type React from "react";
import { useContext, useEffect, useRef, useState } from "react";

import queryString from "query-string";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import { CalendarIcon, CrossIcon, SendIcon } from "assets";
import { useCalendarContext } from "contexts/CalendarContext";
import { useProfileContext } from "contexts/ProfileContext";
import { useSavedInputsContext } from "contexts/SavedInputsContext";
import useSendMessageCall from "routes/messages/queries/useSendMessage";
import { Container, DesktopButton, RelativeWrapper, StyledWrapper } from "routes/messages/styles/input";
import type { Medium, Purpose } from "routes/messages/types";
import { isPatientVisible } from "routes/patients/PatientProfile/components/PatientHeader/PatientHeaderInfo/helpers";
import TextArea from "shared/atoms/inputs/TextArea";
import { QUERY_KEYS } from "types/QueryKeys";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
import { useActivityLogging } from "utils/hooks/useActivityLogging";
import { isRTM } from "utils/patient";

import { BookingForm } from "../BookingForm";
import ScheduleForm from "../ScheduleForm";

interface Props {
  patientId: number;
  smartMessageReply: string;
  onSuccess: () => void;
}

type GetValues = ReturnType<typeof useForm>["getValues"];
type Messages = { [key: number]: string };

const MessageInput: React.VFC<Props> = ({ patientId, smartMessageReply, onSuccess }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const values = queryString.parse(location.search) as { meeting_purpose: Purpose; meeting_medium: Medium };
  const [show, setShow] = useState(values.meeting_purpose !== undefined);
  const getValuesRef = useRef<GetValues | null>(null);
  const [error, setError] = useState("");
  const { savedMessages, setSavedMessages } = useSavedInputsContext();
  const textAreaRef = useRef<HTMLTextAreaElement>();
  const queryClient = useQueryClient();
  const { profile } = useProfileContext();
  const { patient } = useContext(CurrentPatientContext);
  const { showMessagesForm, setShowMessagesForm } = useCalendarContext();
  const { trackActivity } = useActivityLogging();
  const startTime = useRef(new Date().toISOString());

  invariant(profile);

  const hasBookingV2 = profile?.therapist_profile?.feature_flags?.includes("BOOKING_V2");

  useEffect(() => {
    return () => {
      if (getValuesRef.current) {
        const inputValue = getValuesRef.current("message");
        if (inputValue) {
          setSavedMessages((m: Messages) => ({ ...m, [patientId]: inputValue }));
        } else if (inputValue === "" && savedMessages[patientId]) {
          setSavedMessages((m: Messages) => {
            const messages = { ...m };
            delete messages[patientId];
            return messages;
          });
        }
      }
    };
  }, [patientId]);

  const { register, handleSubmit, reset, setValue, getValues } = useForm();
  const { mutateAsync: sendMessage, isLoading: isSendingMessage } = useSendMessageCall();

  useEffect(() => {
    setError("");
    if (smartMessageReply) {
      setValue("message", smartMessageReply);
    } else {
      setValue("message", savedMessages[patientId] ? savedMessages[patientId] : "");
    }
  }, [patientId, smartMessageReply]);

  useEffect(() => {
    getValuesRef.current = getValues;
  });

  const onSubmit = handleSubmit(async ({ message }) => {
    sendMessage({
      to: patientId,
      body: message,
    })
      .then(() => {
        AnalyticsService.track(AnalyticsEvents.MESSAGES.SEND_MESSAGE, {
          patient: patientId,
        });
        if (patientId && isRTM(profile)) {
          trackActivity({
            category: "message_sent",
            patient_id: patientId,
            start_time: startTime.current,
            end_time: new Date().toISOString(),
          });
        }
        setError("");
        onSuccess();
        if (savedMessages[patientId]) {
          setSavedMessages((m: Messages) => {
            const messages = { ...m };
            delete messages[patientId];
            return messages;
          });
        }
        reset();
        queryClient.invalidateQueries(QUERY_KEYS.conversations);
      })
      .catch(() => {
        setError(t("errors.generic"));
      });
  });

  const messagePlaceholder = (): string => {
    let placeHolder = t("messages.input_placeholder");

    if (profile?.active_time_off_period !== null) {
      placeHolder = t("messages.outofoffice_placeholder");
    } else if (!isPatientVisible(patient)) {
      placeHolder = t("messages.selfcare_placeholder");
    }

    return placeHolder;
  };

  const isMessagingDisabled = !isPatientVisible(patient) || profile?.active_time_off_period != null;

  return (
    <Wrapper>
      <RelativeWrapper>
        {hasBookingV2 && showMessagesForm && (
          <BookingForm
            closeCallback={(scheduled: boolean) => {
              if (scheduled) onSuccess();
              setShowMessagesForm(false);
            }}
          />
        )}
        {!hasBookingV2 && (
          <ScheduleForm
            show={show}
            closeCallback={(scheduled: boolean) => {
              if (scheduled) onSuccess();
              setShow(false);
            }}
            selectedPurpose={values.meeting_purpose?.toUpperCase() as Purpose}
            selectedMedium={values.meeting_medium?.toUpperCase() as Medium}
            patientId={patientId}
          />
        )}

        <form data-testid="message-form" onSubmit={onSubmit}>
          <Container $error={error}>
            <StyledWrapper>
              <TextArea
                rows={2}
                padding="0px"
                expandToHeight={140}
                placeholder={messagePlaceholder()}
                {...register("message", {
                  required: true,
                  validate: value => {
                    return !!value.trim();
                  },
                })}
                error={error}
                scrollbarVisible={false}
                disabled={isMessagingDisabled}
                externalRef={textAreaRef}
                onValueUpdate={value => setValue("message", value)}
                emojiPicker
                noBorder
                extrasOffset="45px"
                showQuickReplies
              />
            </StyledWrapper>
            {!isMessagingDisabled && (
              <Toggle
                data-testid="schedule-form-toggle"
                onClick={() => {
                  if (hasBookingV2) {
                    setShowMessagesForm(!showMessagesForm);
                  } else {
                    setShow(!show);
                  }
                }}
                title={t("messages.tooltip.schedule_appointment")}
              >
                {hasBookingV2 && (
                  <>
                    <Calendar $show={showMessagesForm} />
                    <Cross $show={showMessagesForm} />
                  </>
                )}
                {!hasBookingV2 && (
                  <>
                    <Calendar $show={show} />
                    <Cross $show={show} />
                  </>
                )}
              </Toggle>
            )}
            <ButtonsWrapper>
              <DesktopButton type="submit" disabled={isSendingMessage || isMessagingDisabled}>
                <SendIcon />
              </DesktopButton>
            </ButtonsWrapper>
          </Container>
        </form>
      </RelativeWrapper>
    </Wrapper>
  );
};

export default MessageInput;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Toggle = styled.div`
  &:hover {
    opacity: 0.7;
  }
  cursor: pointer;
  position: absolute;
  bottom: 8px;
  left: 4px;
  width: 24px;
  height: 24px;
  padding: 4px ${props => props.theme.spacing.S_10};
  border-right: 1px solid ${props => props.theme.colors.greys.silver};
`;

const Calendar = styled(CalendarIcon)<{ $show: boolean }>`
  opacity: ${props => (props.$show ? "0" : "1")};
  transition: opacity 300ms 0ms;
  position: absolute;
`;

const Cross = styled(CrossIcon)<{ $show: boolean }>`
  opacity: ${props => (props.$show ? "1" : "0")};
  transition: opacity 300ms 0ms;
  position: absolute;
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  right: 12px;
  bottom: 8px;
`;
