import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";

import { CrossIcon } from "assets";
import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import Hidden from "shared/atoms/Hidden";
import Modal from "shared/atoms/Modal";
import { PatientsColumnsContext, PatientsFilterContext } from "utils/contexts";
import { Feature } from "utils/features/types";
import { useQueryParams, useWindowSize } from "utils/hooks";
import joinUrl from "utils/url/joinUrl";

import type { DataColumnsContextType } from "../dataColumns";
import type { Joint, Patient } from "../queries/fetchPatients";
import { TherapistAssignmentRole } from "../queries/TherapistAssignmentRole";
import type { UsePatientQuery } from "../usePatients";

import CustomizeColumns from "./CustomizeColumns";
import { ClearAllButton, CloseBtn, Container, Input, Sidebar, StyledCheckBox, Title } from "./helpers";
import PrimaryJointsFilter from "./PrimaryJointsFilter";
import stringifyJoint from "./stringifyJoint";
import type { Filter, Filters } from "./useFilters";

type PatientsFilterProps = {
  close: () => void;
  open: boolean;
  patientsQuery: UsePatientQuery;
};

const PatientsFilter: React.VFC<PatientsFilterProps> = ({ open, close, patientsQuery }) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const { width } = useWindowSize();
  const { activeFilters, toggleFilter, setFilter, clearAll } = useContext<Filters>(PatientsFilterContext);
  const { hasFeature } = useFeatureToggleContext();
  const [checkedWeek, setCheckedWeek] = useState<string | null>(null);
  const { enabledColumns } = useContext<DataColumnsContextType>(PatientsColumnsContext);
  const { unfilteredPatients } = patientsQuery;
  const params = useQueryParams();
  const navigate = useNavigate();
  const match = useMatch("*");

  const joints = useMemo(() => getJoints(unfilteredPatients), [unfilteredPatients]);

  useEffect(() => {
    if (!activeFilters.includes("week")) setCheckedWeek(null);
  }, [activeFilters]);

  const statuses: Filter[] = ["active", "inactive"];
  if (hasFeature(Feature.ACCESS_TO_CLOSED_ACCOUNTS)) {
    statuses.push("deleted");
  }
  const renderedStatuses = statuses.map((status: Filter) => {
    return (
      <StyledCheckBox
        key={`filter-${status}`}
        name={`filter-${status}`}
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label={t(`patients.statuses.${status}`)}
        checked={activeFilters.includes(status)}
        onChange={() => {
          toggleFilter(status);
        }}
      />
    );
  });

  const tasks: Filter[] = ["new_patients", "new_comments", "new_messages", "appointment_to_schedule"];
  if (hasFeature(Feature.SHOW_AWAITING_TREATMENT_READY)) {
    tasks.push("awaiting_treatment_ready");
  }

  const renderedTasks = tasks.map((task: Filter) => {
    return (
      <StyledCheckBox
        key={`filter-${task}`}
        name={`filter-${task}`}
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label={t(`patients.${task}`)}
        checked={activeFilters.includes(task)}
        onChange={() => {
          toggleFilter(task);
        }}
      />
    );
  });

  const categories: Filter[] = ["prio", "hybrid_care"];
  const renderedCategories = categories.map((category: Filter) => {
    return (
      <StyledCheckBox
        key={`filter-${category}`}
        name={`filter-${category}`}
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label={t(`patients.${category}`)}
        checked={activeFilters.includes(category)}
        onChange={() => {
          toggleFilter(category);
        }}
      />
    );
  });

  const membershipStatuses: Filter[] = ["selfcare", "guided_care"];

  const renderedMembershipStatuses = membershipStatuses.map((status: Filter) => {
    return (
      <StyledCheckBox
        key={`filter-${status}`}
        name={`filter-${status}`}
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label={t(`patients.${status}`)}
        checked={activeFilters.includes(status)}
        onChange={() => {
          toggleFilter(status);
        }}
      />
    );
  });

  const assignmentRoles: TherapistAssignmentRole[] = [
    TherapistAssignmentRole.MainTherapist,
    TherapistAssignmentRole.SubstituteTherapist,
  ];
  const assignmentRolesPhrases: Record<TherapistAssignmentRole, string> = {
    [TherapistAssignmentRole.MainTherapist]: t(`patients.main_therapist`),
    [TherapistAssignmentRole.SubstituteTherapist]: t(`patients.substitute_therapist`),
  };
  const renderedAssignmentRoles = assignmentRoles.map((role: TherapistAssignmentRole) => {
    return (
      <StyledCheckBox
        key={`filter-${role}`}
        name={`filter-${role}`}
        label={assignmentRolesPhrases[role]}
        checked={activeFilters.includes(role)}
        onChange={() => {
          toggleFilter(role);
        }}
      />
    );
  });

  const getWeekInputValues = (checked: string): [string, string] => {
    switch (checked) {
      case "fromTo": {
        const fromInput = document.getElementById("fromInput") as HTMLInputElement;
        const toInput = document.getElementById("toInput") as HTMLInputElement;

        return [fromInput.value, toInput.value];
      }
      case "fromOn": {
        const biggerThan = document.getElementById("bigger_than") as HTMLInputElement;
        return [biggerThan.value, "100"];
      }
      case "equalTo": {
        const weekEqual = document.getElementById("week_equal") as HTMLInputElement;
        return [weekEqual.value, weekEqual.value];
      }
      default:
        return ["0", "0"];
    }
  };

  const updateWeekFilter = (id: string) => {
    const [from, to] = getWeekInputValues(id);
    const fromInt = parseInt(from, 10);
    const toInt = parseInt(to, 10);
    setFilter("week", {
      active: true,
      from: Number.isNaN(fromInt) ? undefined : fromInt,
      to: Number.isNaN(toInt) ? undefined : toInt,
    });
  };

  const toggleCheckedWeek = (id: string) => {
    const isChecked = checkedWeek === id;
    setCheckedWeek(isChecked ? null : id);
    if (isChecked) {
      setFilter("week", { active: false });
    } else {
      updateWeekFilter(id);
    }
  };

  const onInputChange = (id: string) => {
    if (width > Number.parseInt(theme.breakpoint, 10) && checkedWeek === id) {
      updateWeekFilter(id);
    }
  };

  const renderedWeeks = () => {
    return (
      <>
        <StyledCheckBox
          name="filter-week-0"
          label={t(`patients.introduction`)}
          checked={checkedWeek === "intro"}
          onChange={() => {
            toggleCheckedWeek("intro");
          }}
        />

        <StyledCheckBox
          name="filter-week-equal"
          label={t(`patients.week`)}
          checked={checkedWeek === "equalTo"}
          onChange={() => {
            toggleCheckedWeek("equalTo");
          }}
        >
          <Input id="week_equal" type="number" defaultValue={1} onChange={() => onInputChange("equalTo")} />
        </StyledCheckBox>

        <StyledCheckBox
          name="filter-week-from-to"
          label={t(`patients.week`)}
          checked={checkedWeek === "fromTo"}
          onChange={() => {
            toggleCheckedWeek("fromTo");
          }}
        >
          <Input id="fromInput" type="number" defaultValue={1} onChange={() => onInputChange("fromTo")} />
          {t(`patients.to`)}
          <Input id="toInput" type="number" defaultValue={3} onChange={() => onInputChange("fromTo")} />
        </StyledCheckBox>

        <StyledCheckBox
          name="filter-week-and-up"
          label={t(`patients.week`)}
          checked={checkedWeek === "fromOn"}
          onChange={() => {
            toggleCheckedWeek("fromOn");
          }}
        >
          <Input id="bigger_than" type="number" defaultValue={1} onChange={() => onInputChange("fromOn")} />
          {t(`patients.and_up`)}
        </StyledCheckBox>
      </>
    );
  };

  const objectsToRender = [
    { items: renderedTasks, title: "tasks" },
    { items: renderedStatuses, title: "status" },
    { items: renderedWeeks(), title: "week" },
    { items: renderedCategories, title: "category" },
    { items: renderedAssignmentRoles, title: "assignmentRole" },
    { items: renderedMembershipStatuses, title: "membershipStatus" },
  ];

  if (enabledColumns.primaryJoint) {
    objectsToRender.push({
      items: <PrimaryJointsFilter joints={joints} />,
      title: "ailment",
    });
  }

  const titles = objectsToRender.map(({ items, title }) => {
    return (
      <React.Fragment key={title}>
        {/* FIXME: type translation */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Title>{t(`patients.${title}`)}</Title>
        {items}
      </React.Fragment>
    );
  });

  const onClearAll = useCallback(() => {
    clearAll();
    params.delete("filter");
    if (match) {
      navigate(joinUrl(match?.pathname, params));
    }
  }, [clearAll, params, navigate, match?.pathname]);

  return (
    <>
      <Hidden type="belowTablet">
        <Sidebar open={open}>
          <CloseBtn data-testid="close-button" onClick={close}>
            <CrossIcon />
          </CloseBtn>
          {open && (
            <Container data-testid="filter-container">
              {titles}
              <ClearAllButton
                data-testid="clear-all-filters"
                type="button"
                onClick={onClearAll}
                disabled={activeFilters.length === 0}
              >
                {t(`patients.clear`)}
              </ClearAllButton>
              <CustomizeColumns />
            </Container>
          )}
        </Sidebar>
      </Hidden>

      <Hidden type="aboveTablet">
        <Modal title={t("patients.filters")} open={open} close={close}>
          <Container data-testid="filter-container">
            {titles}
            <ClearAllButton
              data-testid="clear-all-filters"
              type="button"
              onClick={onClearAll}
              disabled={activeFilters.length === 0}
            >
              {t(`patients.clear`)}
            </ClearAllButton>
            <CustomizeColumns />
          </Container>
        </Modal>
      </Hidden>
    </>
  );
};

export default PatientsFilter;

const getJoints = (patients: Patient[]) => {
  const uniqueJoints = patients.reduce<Record<string, Joint>>((accumulator, patient) => {
    const { ailment } = patient;
    const joint = patient.primaryJoint;

    if (ailment === "joint_pain" && joint) {
      accumulator[stringifyJoint(joint)] = joint;
    }
    return accumulator;
  }, {});

  return Object.values(uniqueJoints).sort((a, b) => {
    // Sort alphabetically by `location` and then by `lateralLocation`
    if (a.location < b.location) {
      return -1;
    }
    if (a.location > b.location) {
      return 1;
    }
    if (a.lateralLocation < b.lateralLocation) {
      return -1;
    }
    if (a.lateralLocation > b.lateralLocation) {
      return 1;
    }
    return 0;
  });
};
