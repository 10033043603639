import type React from "react";
import { useContext, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { ThemeContext } from "styled-components";
import invariant from "ts-invariant";

import { getCareEventsSuggestedQueryKey } from "api/hooks/useGetCareEventsSuggested";
import { getPatientCareEventsQueryKey } from "api/hooks/useGetPatientCareEventsQuery";
import { usePostCareEventSuggested } from "api/hooks/usePostCareEventSuggested";
import { useMergeCareEvent } from "api/hooks/usePutPatientCareEventMutation";
import type { CareEvent } from "api/models/CareEvent";
import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import { useProfileContext } from "contexts/ProfileContext";
import { CommunicationGroupIcon } from "routes/dashboard/default/components/MedicalNotesTable/CommunicationGroupIcon";
import { CPTCode } from "routes/dashboard/default/components/MedicalNotesTable/CPTCode";
import Hidden from "shared/atoms/Hidden";
import Modal from "shared/atoms/Modal";
import type { ICDCodeResponse } from "types";
import { CurrentPatientContext } from "utils/contexts";
import type { UseGetPatientFullDetailsQueryData } from "utils/contexts/providers/useGetPatientsFullDetailsQuery";
import { Feature } from "utils/features/types";
import { useWindowSize } from "utils/hooks";
import { isKickOffNote } from "utils/medicalNotes";

import type { AutosaveStatus } from "../AutosaveIndicator";
import AutosaveIndicator from "../AutosaveIndicator";
import DynamicMedicalNoteForm from "../DynamicMedicalNoteForm";
import MedicalNoteDate from "../MedicalNoteDate";
import MedicalNoteLabel from "../MedicalNoteLabel";

import {
  AnimatedDropdownIcon,
  FormTD,
  FormTR,
  Label,
  MedicalNoteDateContainer,
  NoteTypeLabel,
  NotesTableCellContainer,
  NotesTableCellContainerLeft,
  NotesTableCellContainerRight,
  ShowButton,
  SignFirstBadge,
  TD,
  TR,
} from "./noteStyles";

const MedicalNote: React.VFC<CareEvent> = ({ ...careEvent }) => {
  const {
    id,
    communication_group,
    cpt_codes,
    message_interaction_id,
    label,
    rtm_care_event_log_id,
    start_time,
    suggested,
    user_course_week_id,
    week_number,
  } = careEvent;
  const [open, setOpen] = useState(false);
  const [autosaveStatus, setAutosaveStatus] = useState<AutosaveStatus>("idle");
  const theme = useContext(ThemeContext);
  const { profile } = useProfileContext();
  invariant(profile, "Profile context not set");
  const { hasFeature } = useFeatureToggleContext();
  const { activeCareEventNote, setActiveCareEventNote, patient } = useContext<{
    activeCareEventNote: number;
    setActiveCareEventNote: (id: number) => void;
    availableICDCodes: ICDCodeResponse[];
    patient?: UseGetPatientFullDetailsQueryData;
  }>(CurrentPatientContext);
  invariant(patient, "Patient context not set");
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const { mergeCareEvent } = useMergeCareEvent();
  const createCareEventSuggested = usePostCareEventSuggested();

  const isEditingDateEnabled = hasFeature(Feature.EDIT_NOTE_DATE) && label.toUpperCase() !== "CHAT";

  const noreRef = useRef<HTMLTableRowElement | null>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (activeCareEventNote) {
      const isCareEventSelected = activeCareEventNote === id;
      setOpen(isCareEventSelected);

      if (isCareEventSelected) {
        setTimeout(() => {
          if (noreRef.current !== null) {
            noreRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }, 500);
      }
    }
  }, [activeCareEventNote]);

  const onChangeDate = (date: string) => {
    mergeCareEvent({
      careEventId: id,
      data: { start_time: date },
      skipInvalidation: true,
    });
  };

  const startTime = start_time ?? "";

  const onClick = () => {
    if (suggested && !open && (message_interaction_id || rtm_care_event_log_id || user_course_week_id)) {
      createCareEventSuggested.mutateAsync(
        {
          data: {
            cpt_codes,
            label: careEvent.label,
            message_interaction_id,
            rtm_care_event_log_id,
            user_course_week_id,
          },
          patientID: patient.id,
        },
        {
          onSuccess(data) {
            const queryKey = getPatientCareEventsQueryKey({ patientId: patient.id, signed: false });
            const previousCareEvents = queryClient.getQueryData<CareEvent[]>(queryKey);
            if (previousCareEvents) {
              queryClient.setQueryData<CareEvent[]>(queryKey, [...previousCareEvents, data]);
            }
            queryClient.invalidateQueries(getCareEventsSuggestedQueryKey([patient.id]));
            setActiveCareEventNote(data.id);
          },
        }
      );
    }
    setOpen(prevState => !prevState);
  };

  return (
    <>
      <TR data-testid="medical_note" open={open} ref={noreRef} onClick={onClick}>
        <TD>
          <NotesTableCellContainerLeft>
            <NoteTypeLabel>
              {cpt_codes && cpt_codes.length > 0 && cpt_codes.map(cptCode => <CPTCode cptCode={cptCode} />)}
              <CommunicationGroupIcon communicationGroup={communication_group} />
              <MedicalNoteLabel careEventLabel={label} weekNumber={week_number} />
            </NoteTypeLabel>
            {isKickOffNote(label) && <SignFirstBadge>{t("patients.notes.sign_first")}</SignFirstBadge>}
            <Hidden type="belowTablet">
              <AutosaveIndicator status={autosaveStatus} />
            </Hidden>
          </NotesTableCellContainerLeft>
        </TD>
        <TD>
          <NotesTableCellContainer>
            <MedicalNoteDate
              defaultDate={startTime}
              onChange={onChangeDate}
              isEditingDateEnabled={isEditingDateEnabled}
              open={open && width > parseInt(theme.breakpoint, 10)}
            />
          </NotesTableCellContainer>
        </TD>
        <TD dataTestId="toggle_medical_note_form">
          <NotesTableCellContainerRight>
            <ShowButton>
              <Label>{open ? t("buttons.hide") : t("buttons.show")}</Label>
              <AnimatedDropdownIcon open={open} />
            </ShowButton>
          </NotesTableCellContainerRight>
        </TD>
      </TR>
      <Hidden type="belowTablet">
        <FormTR open={open}>
          <FormTD colSpan={4} open={open}>
            <DynamicMedicalNoteForm
              careEvent={careEvent}
              animate
              open={open}
              setAutosaveStatus={setAutosaveStatus}
              suggested={suggested}
            />
          </FormTD>
        </FormTR>
      </Hidden>
      <Hidden type="aboveTablet">
        <Modal
          title={<MedicalNoteLabel careEventLabel={label} weekNumber={week_number} />}
          alignTitle="left"
          status={<AutosaveIndicator status={autosaveStatus} />}
          open={open}
          close={() => setOpen(false)}
        >
          {start_time && (
            <MedicalNoteDateContainer>
              <MedicalNoteDate
                defaultDate={startTime}
                onChange={onChangeDate}
                isEditingDateEnabled={isEditingDateEnabled}
                open
              />
            </MedicalNoteDateContainer>
          )}
          <DynamicMedicalNoteForm
            careEvent={careEvent}
            animate
            open={open}
            setAutosaveStatus={setAutosaveStatus}
            suggested={suggested}
          />
        </Modal>
      </Hidden>
    </>
  );
};

export default MedicalNote;
