import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import { QueryClientProvider } from "react-query";
import type { DefaultTheme } from "styled-components";
import { ThemeProvider } from "styled-components";

import { ApiClientProvider } from "api/ApiClientProvider";
import cacheClient from "cacheClient";
import GlobalStyle from "GlobalStyle";
import muiTheme from "muiTheme";
import Router from "router";
import Segment from "shared/atoms/Segment";
import Theme from "theme";
import ErrorBoundary from "utils/errorReporting";
import { useWindowSize } from "utils/hooks";

function App(): JSX.Element {
  const { height } = useWindowSize();

  const themeWithVh = (theme: DefaultTheme) => ({
    ...theme,
    vh: height,
  });

  return (
    <ErrorBoundary>
      <ApiClientProvider>
        <QueryClientProvider client={cacheClient}>
          <ThemeProvider theme={themeWithVh(Theme)}>
            <MUIThemeProvider theme={muiTheme}>
              <Segment />
              <Router />
              <GlobalStyle />
            </MUIThemeProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </ApiClientProvider>
    </ErrorBoundary>
  );
}

export default App;
