import { format, parse } from "date-fns";

import { reportError } from "utils/errorReporting";

export function getDefaultDate(startTime: string | null | undefined) {
  const currentDate = format(new Date(), "yyyy-MM-dd");

  if (!startTime) {
    return currentDate;
  }

  try {
    const parsedDate = parse(startTime, "yyyy-MM-dd'T'HH:mm:ss'Z'", new Date());
    const formattedDate = format(parsedDate, "yyyy-MM-dd");
    return formattedDate;
  } catch (err) {
    if (err instanceof Error || typeof err === "string") {
      reportError("BookingForm.tsx parsing event date", err);
    }
    return currentDate;
  }
}
