import { AxiosInstance } from "api";
import type { CeStructureType, PremiumActivateState, PremiumType } from "api/models/PatientProfile";
import type { UserState } from "api/models/User";
import type { Ailment } from "api/schemas/patients/HealthJournal";
import type { PaymentMethod } from "routes/patients/PatientProfile/components/PatientHeader/PatientHeaderInfo/components/PaymentMethodTag";
import type { PatientStatusRecord } from "shared/molecules/PatientStatus";
import { DataRegionTypes } from "types";
import { Feature } from "utils/features/types";
import { getAppDataRegion } from "utils/region";

import { TherapistAssignmentRole, isTherapistAssignmentRole } from "./TherapistAssignmentRole";

type APIUsInsuranceProfile = {
  name: string;
  insurance_company_name: string;
  status: string;
  medicare: boolean;
  immediate_referral_required: boolean | null;
  pre_authorization_required: boolean | null;
  insurance_company_supported: boolean | undefined;
};

type APIUsProviderGroupProfile = {
  name: string;
};

type APISubscription = {
  is_free_trial: boolean;
  status: string;
};

type AddressState = {
  code: string;
  name: string;
};

type APIPatient = {
  id: number;
  first_name: string;
  last_name: string;
  premium_activation_state: PremiumActivateState;
  status?: string;
  payer_name: string;
  preferred_name: string;
  week: number;
  us_insurance_profiles?: Array<APIUsInsuranceProfile>;
  subscription?: APISubscription;
  us_provider_group_profile?: APIUsProviderGroupProfile;
  appointment_to_schedule: string | null;
  new_comments: boolean;
  new_messages: boolean;
  new_patient: boolean;
  prio: boolean;
  deleted: boolean;
  therapist_assignment_role: TherapistAssignmentRole | null;
  primary_joint?: APIJoint;
  last_activity_completed_at?: string;
  last_login_at?: string;
  billable_care_events?: number;
  premium_activation_occurred_at?: string;
  last_medical_referral_signature_at?: string | null;
  referral_process_status: string | null;
  adherence: number;
  premium_type: PremiumType;
  address_state?: AddressState | null;
  npo_consent?: boolean;
  state: UserState;
  awaiting_treatment_ready: boolean;
  ailment?: Ailment;
  payment_method: PaymentMethod;
  ce_structure?: CeStructureType;
};

type APIJoint = {
  location: string;
  lateral_location: string;
};

type TF = (k: string) => string;

export type Patient = {
  id: number;
  name: string;
  preferredName: string;
  status: string;
  statuses: PatientStatusRecord;
  coverage: string;
  subscription?: string;
  week: number;
  appointment_to_schedule: string | null;
  appointmentToSchedule: boolean;
  newComments: boolean;
  newMessages: boolean;
  newPatient: boolean;
  prio: boolean;
  deleted: boolean;
  therapistAssignmentRole: TherapistAssignmentRole;
  primaryJoint?: Joint;
  lastActivityCompletedAt?: string | null;
  billableCareEvents?: number;
  premiumActivationOccurredAt?: string;
  lastMedicalReferralSignatureAt?: string | null;
  referralProcessStatus: string | null;
  adherence: number;
  premiumType: PremiumType;
  addressStateCode?: string | null;
  npoConsent: boolean | undefined;
  state: UserState;
  awaitingTreatmentReady: boolean;
  ailment?: Ailment;
  paymentMethod: PaymentMethod;
  ce_structure?: CeStructureType;
};

export type Joint = {
  location: string;
  lateralLocation: string;
};

const formatUSCoverage = (t: TF, patient: APIPatient) => {
  const insurance = patient.us_insurance_profiles?.[0];
  const providerGroup = patient.us_provider_group_profile?.name;
  if (providerGroup) {
    return providerGroup;
  }
  if (insurance) {
    const { insurance_company_name: name, medicare, status, insurance_company_supported } = insurance;
    let supported;
    if (insurance_company_supported !== undefined) {
      supported = `, ${
        insurance_company_supported
          ? t("patients.header_info.insurance_supported")
          : t("patients.header_info.insurance_unsupported")
      }`;
    }

    const formattedStatus = status ? ` (${t(`patients.header_info.insurance_status.${status}`)}${supported})` : "";
    const formattedName = medicare ? "Medicare" : name || "";

    return `${formattedName}${formattedStatus}`;
  }
  return "";
};

const formatUKCoverage = (patient: APIPatient) => {
  return patient.payer_name;
};

const formatCoverage = (t: TF, patient: APIPatient) => {
  const currentMarket = getAppDataRegion();
  switch (currentMarket) {
    case DataRegionTypes.US:
      return formatUSCoverage(t, patient);
    case DataRegionTypes.UK:
      return formatUKCoverage(patient);
    default:
      return "";
  }
};

const simplifyPatient = (t: TF, patient: APIPatient): Patient => {
  return {
    id: patient.id,
    name: `${patient.first_name} ${patient.last_name}`,
    preferredName: patient.preferred_name,
    status: patient.status || "inactive",
    coverage: formatCoverage(t, patient),
    subscription: `${patient.subscription?.status ?? ""} ${
      patient.subscription?.is_free_trial ? `(${t("patients.header_info.subscription_free_trial")})` : ""
    }`,
    week: patient.week,
    appointment_to_schedule: patient.appointment_to_schedule,
    appointmentToSchedule: Boolean(patient.appointment_to_schedule),
    newComments: patient.new_comments,
    newMessages: patient.new_messages,
    newPatient: patient.new_patient,
    prio: patient.prio,
    deleted: patient.deleted,
    therapistAssignmentRole: isTherapistAssignmentRole(patient.therapist_assignment_role)
      ? patient.therapist_assignment_role
      : TherapistAssignmentRole.MainTherapist,
    primaryJoint: patient.primary_joint && {
      location: patient.primary_joint.location,
      lateralLocation: patient.primary_joint.lateral_location,
    },
    statuses: {
      deleted: patient.deleted,
      immediate_referral_required: patient.us_insurance_profiles?.some(p => p.immediate_referral_required),
      pre_authorization_required: patient.us_insurance_profiles?.some(p => p.pre_authorization_required),
      status: patient.status,
    },
    lastActivityCompletedAt: patient.last_activity_completed_at,
    billableCareEvents: patient.billable_care_events,
    premiumActivationOccurredAt: patient.premium_activation_occurred_at,
    lastMedicalReferralSignatureAt: patient.last_medical_referral_signature_at,
    referralProcessStatus: patient.referral_process_status,
    adherence: patient.adherence,
    premiumType: patient.premium_type,
    addressStateCode: patient.address_state?.code,
    npoConsent: patient.npo_consent,
    state: patient.state,
    awaitingTreatmentReady: patient.awaiting_treatment_ready,
    ailment: patient.ailment,
    paymentMethod: patient.payment_method,
    ce_structure: patient.ce_structure,
  };
};

export default async (
  therapistID: number,
  hasFeature: (k: Feature) => boolean,
  t: TF,
  enabledColumns: Array<string>,
  extraParams: Record<string, boolean | number | string> = {}
): Promise<Array<Patient>> => {
  // FIXME: REMOVE HACK THAT SUPPORTS BE TYPO AND ALLOWS EASY CORRECTION AFTER BACKEND UPDATE
  const enabledColumnsHack = [...enabledColumns];
  if (enabledColumnsHack.includes("selfCareActivationState")) {
    enabledColumnsHack.push("selfCareActiviationState");
  }
  // FE needs that column info to display NPO alert icon for hip/knee patients
  if (!enabledColumnsHack.includes("primaryJoint")) {
    enabledColumnsHack.push("primaryJoint");
  }

  const { data } = await AxiosInstance.get(`therapists/${therapistID}/patients`, {
    params: {
      include_deleted: hasFeature(Feature.ACCESS_TO_CLOSED_ACCOUNTS) || undefined,
      enabled_columns: enabledColumnsHack, // FIXME: WHEN CORRECTING THE HACK PLEASE REVERT THIS TO enabledColumns
      ...extraParams,
    },
  });

  return data.map((apiPatient: APIPatient) => simplifyPatient(t, apiPatient));
};
