import type React from "react";
import { useContext, useEffect, useState } from "react";

import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";

import useGetCareEvent from "api/hooks/useGetCareEvent";
import useGetCareEventsBilling from "api/hooks/useGetCareEventsBilling";
import { usePutCareEventBilling } from "api/hooks/usePutCareEventBilling";
import type { CareEventBilling, Status } from "api/schemas/CareEventBilling";
import { Header } from "routes/calendar/components/EventDetails/styles";
import { CPTCode } from "routes/dashboard/default/components/MedicalNotesTable/CPTCode";
import {
  Name,
  NameWrapper,
  NameWrapperInner,
  TypeWrapper,
} from "routes/dashboard/default/components/MedicalNotesTable/styles";
import DynamicMedicalNoteForm from "routes/patients/PatientProfile/components/PatientHeader/SignNotesTable/components/DynamicMedicalNoteForm";
import { BaseButton } from "shared/atoms/BaseButton";
import { Notification } from "shared/atoms/Notification";
import Pagination from "shared/atoms/Pagination";
import Popup from "shared/atoms/Popup";
import { Table, TableCell, TableFooter, TableRow, TableSort } from "shared/molecules/Table";
import { CurrentPatientContext } from "utils/contexts";
import { reportError } from "utils/errorReporting";

const LIMIT = 30;

type StatusCounts = Record<Status, number>;

export const AdminBillingView: React.VFC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { patient, setPatientId } = useContext(CurrentPatientContext);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [notes, setNotes] = useState<Array<CareEventBilling>>([]);
  const [statusCounts, setStatusCounts] = useState<StatusCounts>({ completed: 0, discarded: 0, pending: 0 });

  const [filter, setFilter] = useState<Status>("pending");
  const [selectedCareEvent, setSelectedCareEvent] = useState<CareEventBilling | null>(null);
  const [showCareEvent, setShowCareEvent] = useState(false);
  const [showMarkAsCompleted, setShowMarkAsCompleted] = useState(false);
  const [showMarkAsDiscarded, setShowMarkAsDiscarded] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { data: careEvent } = useGetCareEvent(selectedCareEvent ? selectedCareEvent?.care_event_id : 0, {
    enabled: selectedCareEvent !== null,
  });
  const { mutateAsync, isLoading } = usePutCareEventBilling();

  const getColumns = () => {
    switch (filter) {
      case "pending":
        return [
          t("dashboard.tables.notes.labels.patient"),
          t("dashboard.tables.notes.labels.icd"),
          t("dashboard.tables.notes.labels.cpt"),
          t("dashboard.tables.notes.labels.assigned_therapist"),
          t("dashboard.tables.notes.labels.created_at"),
          t("dashboard.tables.notes.labels.signed_at"),
          t("dashboard.tables.notes.labels.complete"),
          t("dashboard.tables.notes.labels.discard"),
        ];
      default:
        return [
          t("dashboard.tables.notes.labels.patient"),
          t("dashboard.tables.notes.labels.icd"),
          t("dashboard.tables.notes.labels.cpt"),
          t("dashboard.tables.notes.labels.assigned_therapist"),
          t("dashboard.tables.notes.labels.handled_by"),
          t("dashboard.tables.notes.labels.handled_at"),
        ];
    }
  };

  const columns = getColumns();

  const [sort, setSort] = useState({
    selectedColumn: columns[2],
    descending: false,
  });

  const { data } = useGetCareEventsBilling();

  const compare = (a: CareEventBilling, b: CareEventBilling) => {
    let x: string | number | Date = "";
    let y: string | number | Date = "";
    switch (sort.selectedColumn) {
      case t("dashboard.tables.notes.labels.patient"):
        x = a.patient_name.toLowerCase();
        y = b.patient_name.toLowerCase();
        break;
      case t("dashboard.tables.notes.labels.icd"):
        x = a.icd_codes?.[0]?.toLowerCase();
        y = b.icd_codes?.[0]?.toLowerCase();
        break;
      case t("dashboard.tables.notes.labels.cpt"):
        if (a.cpt_codes && b.cpt_codes) {
          x = a.cpt_codes[0]?.toLowerCase();
          y = b.cpt_codes[0]?.toLowerCase();
        }
        break;
      case t("dashboard.tables.notes.labels.assigned_therapist"):
        x = a.therapist_name.toLowerCase();
        y = b.therapist_name.toLowerCase();
        break;
      case t("dashboard.tables.notes.labels.handled_by"):
        if (a.manager_name && b.manager_name) {
          x = a.manager_name.toLowerCase();
          y = b.manager_name.toLowerCase();
        }
        break;
      case t("dashboard.tables.notes.labels.handled_at"):
        if (a.handled_at && b.handled_at) {
          x = new Date(a.handled_at);
          y = new Date(b.handled_at);
        }
        break;
      case t("dashboard.tables.notes.labels.created_at"):
        if (a.created_at && b.created_at) {
          x = new Date(a.created_at);
          y = new Date(b.created_at);
        }
        break;
      case t("dashboard.tables.notes.labels.signed_at"):
        if (a.signed_at && b.signed_at) {
          x = new Date(a.signed_at);
          y = new Date(b.signed_at);
        }
        break;
      case t("dashboard.tables.notes.labels.complete"):
      case t("dashboard.tables.notes.labels.discard"):
        break;
      default:
        break;
    }
    if (x === y) {
      x = a.care_event_id;
      y = b.care_event_id;
    }
    if (sort.descending) {
      return y < x ? 1 : -1;
    }
    return x < y ? 1 : -1;
  };

  useEffect(() => {
    if (data) {
      const statusCountsObj = data.reduce((counts, obj) => {
        const { status } = obj;
        return { ...counts, [status]: (counts[status] || 0) + 1 }; // Increment the count for this status
      }, {} as StatusCounts);
      setStatusCounts(statusCountsObj);

      let filteredNotes = data;

      switch (filter) {
        case "completed":
          filteredNotes = filteredNotes.filter(note => note.status === "completed");
          break;
        case "discarded":
          filteredNotes = filteredNotes.filter(note => note.status === "discarded");
          break;
        case "pending":
          filteredNotes = filteredNotes.filter(note => note.status === "pending");
          break;
        default:
          break;
      }

      setTotalCount(filteredNotes.length);

      const sortedNotes = filteredNotes
        .sort(compare)
        .filter((_node: CareEventBilling, index: number) => index >= offset && index < offset + LIMIT);
      setNotes(sortedNotes);
    }
  }, [data, filter, offset, sort]);

  useEffect(() => {
    if (careEvent) {
      setPatientId(careEvent.patient_id);
    } else {
      setPatientId(null);
    }
  }, [careEvent, setPatientId]);

  const hasNextPage = totalCount ? totalCount > offset + LIMIT : false;
  const hasPreviousPage = offset !== 0;

  const onSort = (clickedColumn: string) => {
    if (sort.selectedColumn === clickedColumn) {
      setSort({ selectedColumn: clickedColumn, descending: !sort.descending });
    } else {
      setSort({ selectedColumn: clickedColumn, descending: true });
    }
    setOffset(0);
  };

  const updateCareEventBillingStatus = async (careEventID: number, status: Status) => {
    mutateAsync(
      {
        careEventID,
        data: { status },
      },
      {
        onSuccess: () => {
          setSelectedCareEvent(null);
          setShowMarkAsCompleted(false);
          setShowMarkAsDiscarded(false);
        },
        onError: e => {
          reportError("AppointmentDetails.tsx", e);
          setError(t("errors.generic"));
        },
      }
    );
  };

  const getRowContent = (note: CareEventBilling) => {
    switch (filter) {
      case "pending":
        return (
          <>
            <StyledTypeWrapper>{format(parseISO(note.created_at), "PP")}</StyledTypeWrapper>
            <StyledTypeWrapper>{format(parseISO(note.signed_at), "PP")}</StyledTypeWrapper>
            <StyledTypeWrapper>
              <ButtonContainer
                onClick={() => {
                  setSelectedCareEvent(note);
                  setShowMarkAsCompleted(true);
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color={theme.colors.redesign.g100}
                  style={{ height: "18px", width: "18px" }}
                />
              </ButtonContainer>
            </StyledTypeWrapper>
            <StyledTypeWrapper>
              <ButtonContainer
                onClick={() => {
                  setSelectedCareEvent(note);
                  setShowMarkAsDiscarded(true);
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  color={theme.colors.redesign.r100}
                  style={{ height: "18px", width: "18px" }}
                />
              </ButtonContainer>
            </StyledTypeWrapper>
          </>
        );
      default:
        return (
          <>
            <StyledTypeWrapper>{note.manager_name}</StyledTypeWrapper>
            <StyledTypeWrapper>{note.handled_at && format(parseISO(note.handled_at), "M-d-yyyy")}</StyledTypeWrapper>
          </>
        );
    }
  };

  const colSpan = filter === "pending" ? 3 : 2;

  return (
    <>
      <PageContainer>
        <Container>
          <StyledTable>
            <thead>
              <tr style={{ height: "48px" }}>
                <StyledTH colSpan={colSpan}>
                  <FilterButtonContainer>
                    <BaseButton
                      text={t("admin_billing.buttons.pending")}
                      onClick={() => setFilter("pending")}
                      variant="tertiary"
                      icon="list"
                      iconPlacement="start"
                    />
                    {statusCounts.pending > 0 && <Counter>{statusCounts.pending}</Counter>}
                  </FilterButtonContainer>
                </StyledTH>
                <StyledTH colSpan={colSpan}>
                  <FilterButtonContainer>
                    <BaseButton
                      text={t("admin_billing.buttons.completed")}
                      onClick={() => setFilter("completed")}
                      variant="tertiary"
                      icon="circle-check"
                      iconPlacement="start"
                    />
                    {statusCounts.completed > 0 && <Counter>{statusCounts.completed}</Counter>}
                  </FilterButtonContainer>
                </StyledTH>
                <StyledTH colSpan={colSpan}>
                  <FilterButtonContainer>
                    <BaseButton
                      text={t("admin_billing.buttons.discarded")}
                      onClick={() => setFilter("discarded")}
                      variant="tertiary"
                      icon="box-archive"
                      iconPlacement="start"
                    />
                    {statusCounts.discarded > 0 && <Counter>{statusCounts.discarded}</Counter>}
                  </FilterButtonContainer>
                </StyledTH>
              </tr>
              {notes.length > 0 && (
                <StyledTableSort
                  columns={columns}
                  sortBy={sort.selectedColumn}
                  descending={sort.descending}
                  onSort={onSort}
                />
              )}
            </thead>
            <tbody>
              {notes.length > 0 &&
                notes.map(note => {
                  return (
                    <StyledTableRow key={note.care_event_id}>
                      <StyledNameWrapper>
                        <NameWrapperInner>
                          <Name $label_state={false}>{note.patient_name}</Name>
                        </NameWrapperInner>
                      </StyledNameWrapper>
                      <StyledTypeWrapper>{note.icd_codes?.[0]}</StyledTypeWrapper>
                      <CPTCodeWrapper>
                        {note.cpt_codes &&
                          note.cpt_codes.length > 0 &&
                          note.cpt_codes.map(cptCode => <CPTCode cptCode={cptCode} />)}
                      </CPTCodeWrapper>
                      <StyledTypeWrapper>{note.therapist_name}</StyledTypeWrapper>
                      {getRowContent(note)}
                      <ShowButton
                        onClick={() => {
                          setSelectedCareEvent(note);
                          setShowCareEvent(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} style={{ height: "18px", width: "18px" }} />
                      </ShowButton>
                    </StyledTableRow>
                  );
                })}
              {notes.length <= 0 && (
                <TableRow hoverEnabled={false}>
                  <TableCell>{t("dashboard.tables.notes.no_notes") as string}</TableCell>
                </TableRow>
              )}
            </tbody>
            <tfoot>
              {totalCount > LIMIT && (
                <TableFooter colSpan={columns.length} addTopBorder>
                  <Pagination
                    totalCount={totalCount}
                    first={offset + 1}
                    last={offset + LIMIT < totalCount ? offset + LIMIT : totalCount}
                    pageInfo={{ hasNextPage, hasPreviousPage }}
                    onPageChange={goTo => (goTo === "next" ? setOffset(offset + LIMIT) : setOffset(offset - LIMIT))}
                  />
                </TableFooter>
              )}
            </tfoot>
          </StyledTable>
        </Container>
      </PageContainer>

      {careEvent && showCareEvent && patient && (
        <Popup
          fullBgOpacity
          onClickOutside={() => {
            setSelectedCareEvent(null);
            setShowCareEvent(false);
          }}
          showCloseButton
        >
          <CareEventContainer>
            <DynamicMedicalNoteForm
              careEvent={careEvent}
              animate
              open
              isAdminBillingView
              skipMerge
              readOnly={selectedCareEvent?.status !== "pending"}
              setSelectedCareEvent={setSelectedCareEvent}
            />
          </CareEventContainer>
        </Popup>
      )}

      {showMarkAsCompleted && (
        <Popup fullBgOpacity onClickOutside={() => setShowMarkAsCompleted(false)} showCloseButton>
          <PopupContent>
            <Header>{t("admin_billing.mark_as_completed")}</Header>
            <BaseButton
              text={t("buttons.yes")}
              onClick={() => updateCareEventBillingStatus(selectedCareEvent?.care_event_id as number, "completed")}
              disabled={isLoading}
              loading={isLoading}
              uppercase
              style={{ marginBottom: "20px" }}
            />
            <BaseButton
              text={t("buttons.cancel")}
              onClick={() => setShowMarkAsCompleted(false)}
              disabled={isLoading}
              variant="tertiaryBlue"
              uppercase
            />
            {error && (
              <Notification variant="danger" style={{ marginTop: "16px" }}>
                {error}
              </Notification>
            )}
          </PopupContent>
        </Popup>
      )}

      {showMarkAsDiscarded && (
        <Popup fullBgOpacity onClickOutside={() => setShowMarkAsDiscarded(false)} showCloseButton>
          <PopupContent>
            <Header>{t("admin_billing.mark_as_discarded")}</Header>
            <BaseButton
              text={t("buttons.yes")}
              onClick={() => updateCareEventBillingStatus(selectedCareEvent?.care_event_id as number, "discarded")}
              disabled={isLoading}
              loading={isLoading}
              uppercase
              style={{ marginBottom: "20px" }}
            />
            <BaseButton
              text={t("buttons.cancel")}
              onClick={() => setShowMarkAsDiscarded(false)}
              disabled={isLoading}
              variant="tertiaryBlue"
              uppercase
            />
            {error && (
              <Notification variant="danger" style={{ marginTop: "16px" }}>
                {error}
              </Notification>
            )}
          </PopupContent>
        </Popup>
      )}
    </>
  );
};

const PageContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
`;

const Container = styled.div`
  max-width: 900px;
  margin: 68px auto 0;
  padding: 24px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.redesign.db20};
  border-radius: 8px;
  position: relative;

  ${props => props.theme.belowBreakpoint} {
    margin: 0;
    padding: 0;
  }
`;

const StyledTable = styled(Table)`
  display: block;
  overflow-x: auto;
`;

const StyledTH = styled.th`
  &:not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.colors.redesign.db20};
  }
`;

const FilterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > button {
    height: 100%;
  }
`;

const Counter = styled.div`
  color: ${({ theme }) => theme.colors.redesign.r80};
  font-size: 16px;
  font-weight: normal;
  margin-left: 8px;
`;

const StyledTableSort = styled(TableSort)`
  & > th {
    width: auto;
  }
`;

const StyledTableRow = styled(TableRow)`
  position: relative;
  cursor: default;
`;

const StyledNameWrapper = styled(NameWrapper)`
  width: auto;
`;

export const StyledTypeWrapper = styled(TypeWrapper)`
  width: auto;
`;

const CPTCodeWrapper = styled(StyledTypeWrapper)`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  cursor: pointer;
`;

const ShowButton = styled.td`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 430px;
  padding: 40px 26px 32px;
  text-align: center;
`;

export const CareEventContainer = styled.div`
  width: 770px;
  overflow-y: auto;

  ${props => props.theme.belowBreakpoint} {
    width: auto;
  }
`;
