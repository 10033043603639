import { isValid } from "date-fns";
import { useTranslation } from "react-i18next";

import type { DateString } from "types/DateString";
import useLocalizedDate from "utils/date";

import { BlueLine, Container, Content, Title } from "../InfoHelpers";

interface TherapistChangeProps {
  substituteTherapist: boolean;
  change: string;
  therapistName: string;
  assignmentEndDate: DateString | null;
}

const TherapistChange = ({
  substituteTherapist,
  change,
  therapistName,
  assignmentEndDate,
}: TherapistChangeProps): JSX.Element => {
  const { format, parseISO } = useLocalizedDate();
  const { t } = useTranslation();

  const renderSubstituteTherapistMessage = (): JSX.Element => {
    const isStarting = change === "assignment_started";

    if (isStarting) {
      if (assignmentEndDate && isValid(assignmentEndDate)) {
        return (
          <Title>
            {t("messages.substitute_therapist_change.start_with_end_date", {
              therapistName,
              assignmentEndDate: format(parseISO(assignmentEndDate), "PP"),
            })}
          </Title>
        );
      }

      return <Title>{t("messages.substitute_therapist_change.start", { therapistName })}</Title>;
    }

    return <Title>{t("messages.substitute_therapist_change.end", { therapistName })}</Title>;
  };

  return (
    <Container>
      <BlueLine />
      <Content>
        {substituteTherapist ? (
          renderSubstituteTherapistMessage()
        ) : (
          <Title>{t("messages.main_therapist_change", { therapistName })}</Title>
        )}
      </Content>
    </Container>
  );
};

export default TherapistChange;
