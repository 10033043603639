import type React from "react";

import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { MUIErrorText, MUILabelBase } from "../StyledInputs/MUIStyledInputs";

interface Props {
  readonly disabled?: boolean;
  readonly error?: string;
  readonly label: string;
  readonly name: string;
  readonly required?: boolean;
  readonly reverse?: boolean;
  readonly onChange?: (value: boolean) => void;
}

const MUICheckBox: React.VFC<Props> = ({ disabled, error, label, name, required, reverse, onChange }) => {
  const { control } = useFormContext();
  return (
    <div>
      <StyledFormControlLabel
        control={
          <Controller
            control={control}
            name={name}
            rules={{ required }}
            render={({ field }) => (
              <Checkbox
                data-testid={`checkbox-${name}`}
                disableRipple
                onChange={(event, value) => {
                  field.onChange(event);
                  if (onChange) onChange(value);
                }}
                checked={field.value}
                disabled={disabled}
              />
            )}
          />
        }
        disabled={disabled}
        labelPlacement={reverse ? "start" : "end"}
        label={label}
        name={name}
        $error={!!error}
      />
      {!!error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)<{ $error: boolean }>`
  &.MuiFormControlLabel-root {
    width: 100%;
    align-items: flex-start;
  }
  .MuiFormControlLabel-label {
    ${MUILabelBase}
    padding-top: 13px;
    user-select: none;
  }
  .MuiCheckbox-root {
    margin-bottom: -12px;
    margin-top: -4px;
    color: ${props => props.theme.colors.redesign[props.$error ? "r100" : "db30"]};
    &.Mui-checked {
      color: ${props => props.theme.colors.redesign[props.$error ? "r100" : "b60"]};
    }
    &.Mui-disabled {
      color: ${props => props.theme.colors.greys.silver};
    }
    svg {
      width: 30px;
      height: 30px;
    }
    .MuiButtonBase-root {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 6px;
        left: 6px;
        bottom: 6px;
        right: 6px;
        background-color: #fff;
      }
    }
    &.Mui-focusVisible,
    &.Mui-focusVisible + .MuiTypography-root {
      color: ${props => props.theme.colors.redesign.b60};
    }
  }
  .Mui-checked,
  &.Mui-disabled {
    .MuiIconButton-label:after {
      content: none;
    }
  }
`;

const ErrorText = styled.div`
  ${MUIErrorText}
`;

export default MUICheckBox;
