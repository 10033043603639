import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled, { css, useTheme } from "styled-components";

export type PaymentMethod = "exemption_card" | "free_care" | "invoice" | "none" | null;

interface Props {
  paymentMethod: PaymentMethod;
  small?: boolean;
  style?: React.CSSProperties;
}

export const PaymentMethodTag: React.VFC<Props> = ({ paymentMethod, small, style }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (!paymentMethod || paymentMethod === "none") {
    return null;
  }

  const isExemptionCard = paymentMethod === "exemption_card";

  const getColor = () => {
    switch (paymentMethod) {
      case "exemption_card":
        return theme.colors.redesign.g100;
      default:
        return theme.colors.white;
    }
  };

  const getText = (isSmall?: boolean) => {
    let text: string;

    switch (paymentMethod) {
      case "invoice":
        text = ` - ${t("booking.services.payment_methods_options.invoice")}`;
        break;
      case "exemption_card":
        text = t("booking.services.payment_methods_options.exemption_card");
        break;
      case "free_care":
        text = ` - ${t("booking.services.payment_methods_options.free_care")}`;
        break;
      default:
        text = "";
        break;
    }

    return isSmall ? text.charAt(0) : text;
  };

  const getTag = () => {
    return (
      <Tag $color={getColor()} $isExemptionCard={isExemptionCard} style={{ ...style }}>
        {getText(small)}
      </Tag>
    );
  };

  return (
    <>
      {small ? (
        <Tooltip placement="top" title={getText()}>
          {getTag()}
        </Tooltip>
      ) : (
        getTag()
      )}
    </>
  );
};

const Tag = styled.span<{ $color: string; $isExemptionCard: boolean }>`
  ${({ $isExemptionCard }) =>
    $isExemptionCard &&
    css`
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.redesign.g100};
      font-weight: 700;
      padding: 0 8px;
      border-radius: 8px;
    `}
`;
