import { adaptV4Theme, createTheme } from "@mui/material";

import theme from "../theme";

const muiTheme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiAccordion: {
        root: {
          background: theme.colors.greys.light4,
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: 14,
        },
      },
      MuiInputBase: {
        root: {
          fontSize: 14,
        },
      },
      MuiAutocomplete: {
        option: {
          "&[aria-selected=true]": {
            backgroundColor: theme.colors.redesign.b30,
          },
        },
        paper: {
          boxShadow: "0px 10px 20px rgba(141, 155, 173, 0.3);",
        },
      },
    },
    palette: {
      primary: {
        main: theme.colors.redesign.b60,
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    shape: {
      borderRadius: 8,
    },
  })
);

export default muiTheme;
