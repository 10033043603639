import type { ChangeEvent } from "react";
import type React from "react";

import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createFilterOptions } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import MUIAutocomplete from "shared/atoms/inputs/MUIAutocomplete";
import { MenuItem, SelectedItem } from "shared/atoms/inputs/MUIMultiselectDropdown";
import { Tooltip } from "shared/atoms/Tooltip";
import theme from "theme";
import { getFieldOptionKey } from "utils/medicalNotes";

interface Props {
  readonly disabled?: boolean;
  readonly error?: string;
  readonly label?: string;
  readonly name: string;
  readonly required?: boolean;
  readonly options: string[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly onChange?: (event: ChangeEvent<{}>) => void;
}

export const CPTAutocomplete: React.VFC<Props> = ({ disabled, error, label, name, required, options, onChange }) => {
  const { setValue } = useFormContext();
  const { t } = useTranslation();

  return (
    <MUIAutocomplete
      disableCloseOnSelect
      disabled={disabled}
      error={error}
      label={label}
      name={name}
      options={options}
      required={required}
      onChange={(event, value) => {
        setValue(name, value, {
          shouldDirty: true,
          shouldValidate: true,
        });
        if (onChange) onChange(event);
      }}
      filterOptions={createFilterOptions({
        stringify: (option: string) => {
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return t(getFieldOptionKey(name, option)) as string;
        },
      })}
      renderOption={(props, option) => (
        <StyledMenuItem name={name} option={option} {...props}>
          <MenuItemContent>
            {/* FIXME: type translation */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {t(getFieldOptionKey(name, option))}
            {/* FIXME: type translation */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Tooltip title={t(getFieldOptionKey(name, `${option}_description`))} placement="top" arrow>
              <div>
                <FontAwesomeIcon icon={faCircleInfo} color={theme.colors.redesign.db90} />
              </div>
            </Tooltip>
          </MenuItemContent>
        </StyledMenuItem>
      )}
      renderTag={(valueArray, getTagProps) =>
        valueArray.map((value, i) => (
          <SelectedItem
            key={value}
            // FIXME: type translation
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label={t(getFieldOptionKey(name, value)) as string}
            name={name}
            option={value}
            tagProps={getTagProps({ index: i })}
          />
        ))
      }
    />
  );
};

const StyledMenuItem = styled(MenuItem)`
  ${props => props.theme.belowBreakpoint} {
    height: 100%;
  }
`;

const MenuItemContent = styled.div`
  display: flex;

  svg {
    margin-left: 16px;
  }
`;
