import { useContext, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import { useCreateUserCourseTemplate } from "api/hooks/useCreateUserCourseTemplate";
import { useDeleteUserCourseTemplate } from "api/hooks/useDeleteUserCourseTemplate";
import { useGetUserCourseQuery } from "api/hooks/useGetUserCourse";
import { useGetUserCourseTemplateQuery } from "api/hooks/useGetUserCourseTemplate";
import { GET_USER_COURSE_TEMPLATES_QUERY_KEY, useGetUserCourseTemplates } from "api/hooks/useGetUserCourseTemplates";
import { usePostMarkTreatmentReady } from "api/hooks/usePostMarkTreatmentReady";
import { usePutUserCourseQuery } from "api/hooks/usePutUserCourse";
import { useUpdateUserCourseTemplate } from "api/hooks/useUpdateUserCourseTemplate";
import type {
  HealthFormType,
  UserCourseExercise,
  UserCourseLesson,
  UserCourseLibraryExercise,
  UserCourseLibraryExercises,
  UserCourseTemplateExercise,
  UserCourseTrimmedExercise,
} from "api/schemas/UserCourse";
import { useProfileContext } from "contexts/ProfileContext";
import { Notification } from "shared/atoms/Notification";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
import { useActivityLogging } from "utils/hooks/useActivityLogging";
import { isRTM } from "utils/patient";

import Drawer from "./components/Drawer";
import ExerciseEditPopup from "./components/ExerciseEditPopup";
import ExercisePicker from "./components/ExercisePicker";
import ExercisesList from "./components/ExercisesList";
import LessonPicker from "./components/LessonPicker";
import LessonsList from "./components/LessonsList";
import MarkTreatmentReadyConfirmation from "./components/MarkTreatmentReadyConfirmation";
import Summary from "./components/Summary";
import TemplatePopup from "./components/TemplatePopup";
import Templates from "./components/Templates";
import type { Tour } from "./components/TourPlayer";
import TourPlayer from "./components/TourPlayer";
import NoExercisesConfirmDialog from "./NoExercisesConfirmDialog";
import RemoveExercisesConfirmDialog from "./RemoveExercisesConfirmDialog";
import isSameCourse, { removeUnchangedLevels } from "./utils/isSameCourse";

const sanitizeAndCloneExercises = (exercises: UserCourseExercise[]) => {
  const deepCopy: UserCourseExercise[] = JSON.parse(JSON.stringify(exercises));
  return deepCopy.map(e => ({
    ...e,
    current_level: e.current_level > 1 ? e.current_level : 1, // some exercises could have current_level 0 for some mysterious reason
  }));
};

const sanitizeAndCloneLessons = (lessons: UserCourseLesson[]) => {
  const deepCopy: UserCourseLesson[] = JSON.parse(JSON.stringify(lessons));
  const finalLessons: UserCourseLesson[] = deepCopy.map(e => ({
    ...e,
    target_joints: ["hip", "knee"],
  }));
  return finalLessons;
};

const convertTemplateExercisesToUsercourseExercises = (
  exercises: UserCourseTemplateExercise[]
): UserCourseExercise[] => {
  const deepCopy: UserCourseTemplateExercise[] = JSON.parse(JSON.stringify(exercises));
  return deepCopy.map(e => ({
    current_level: 1,
    active: false,
    ...e,
  }));
};

const createCoursePayload = ({
  exercises,
  lessons,
  exercisesPerDay,
  functionalTest,
  healthFormType,
}: {
  exercises: UserCourseTrimmedExercise[];
  lessons: UserCourseLesson[];
  exercisesPerDay: number;
  functionalTest: boolean;
  healthFormType: HealthFormType;
}) => {
  const cleanedExercises = exercises.map(e => {
    const { id, introduction_protocol_week, current_level, end_protocol_week } = e;
    return {
      exercise_id: Number(id),
      introduction_protocol_week,
      ...(current_level ? { level_recommendation: current_level } : {}),
      end_protocol_week,
    };
  });

  const cleanedLessons = lessons.map(e => {
    return {
      lesson_id: Number(e.id),
    };
  });

  return {
    exercises: cleanedExercises,
    lessons: cleanedLessons,
    max_exercises_per_day: exercisesPerDay,
    functional_test: functionalTest,
    health_form_type: healthFormType,
  };
};

export type UserCourseView = "exercises" | "lessons";
export interface MissingExercises {
  weeks: number[];
  overridden: boolean;
}

const UserCourseScreen = () => {
  const [exercisesPerDay, setExercisesPerDay] = useState<number>(1);
  const [showExercisePicker, setShowExercisePicker] = useState(false);
  const [showLessonPicker, setShowLessonPicker] = useState(false);
  const [userExercises, setUserExercises] = useState<UserCourseExercise[]>([]);
  const [userLessons, setUserLessons] = useState<UserCourseLesson[]>([]);
  const [showTemplatePopup, setShowTemplatePopup] = useState<"none" | "save" | "use" | "delete" | "replace">("none");
  const [showMarkTreatmentReadyConfirmation, setShowMarkTreatmentReadyConfirmation] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState<UserCourseExercise | undefined>();
  const [newlyAddedExercises, setNewlyAddedExercises] = useState<number[]>([]);
  const [newlyAddedLessons, setNewlyAddedLessons] = useState<number[]>([]);
  const [isPristine, setIsPristine] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(-1);
  const [functionalTest, setFunctionalTest] = useState(false);
  const [healthFormType, setHealthFormType] = useState<HealthFormType>();
  const [view, setView] = useState<UserCourseView>("exercises");
  const [tour, setTour] = useState<Tour | null>(null);
  const [showClearConfirm, setShowClearConfirm] = useState(false);
  const previousStateRef = useRef<UserCourseExercise[]>([]);
  const [missingExercises, setMissingExercises] = useState<MissingExercises>({
    weeks: [],
    overridden: false,
  });

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { patient, refreshPatient } = useContext(CurrentPatientContext);
  const patientId = patient.id;
  const currentProtocolWeek = patient.week;
  const { profile } = useProfileContext();
  invariant(profile);
  const activatedCourse = !patient.awaiting_treatment_ready;

  const [doSave, setDoSave] = useState(false);
  const navigate = useNavigate();
  const { trackActivity } = useActivityLogging();
  const startTime = useRef(new Date().toISOString());

  // fetch and save
  const {
    data: courseData,
    error: courseIsError,
    refetch: refetchCourseData,
  } = useGetUserCourseQuery(
    { patientId },
    {
      enabled: false,
      onSuccess: data => {
        if (data?.exercises) {
          setUserExercises(sanitizeAndCloneExercises(data.exercises));
        }
        if (data?.max_exercises_per_day) {
          setExercisesPerDay(data.max_exercises_per_day);
        }
        if (data?.lessons) {
          setUserLessons(sanitizeAndCloneLessons(data.lessons));
        }
        setFunctionalTest(data.functional_test);
        setHealthFormType(data.health_form_type);
      },
    }
  );

  const { data: templatesData, error: templatesIsError } = useGetUserCourseTemplates();

  const { mutateAsync: putCourseMutate, error: putCourseIsError } = usePutUserCourseQuery(
    { patientId },
    {
      onSuccess: () => {
        refetchCourseData();
      },
    }
  );

  const { mutateAsync: markTreatmentMutate, error: markTreatmentIsError } = usePostMarkTreatmentReady(
    { patientId },
    {
      onSuccess: () => {
        if (isRTM(profile)) {
          trackActivity({
            category: "treatment_created",
            patient_id: patientId,
            start_time: startTime.current,
            end_time: new Date().toISOString(),
          });
        }
        setShowMarkTreatmentReadyConfirmation(true);
        refreshPatient();
      },
    }
  );

  const { mutateAsync: createTemplateMutate, error: createTemplateIsError } = useCreateUserCourseTemplate({
    onSuccess: () => {
      queryClient.invalidateQueries(GET_USER_COURSE_TEMPLATES_QUERY_KEY);
    },
  });

  const { mutateAsync: updateTemplateMutate, error: updateTemplateIsError } = useUpdateUserCourseTemplate({
    onSuccess: () => {
      queryClient.invalidateQueries(GET_USER_COURSE_TEMPLATES_QUERY_KEY);
    },
  });

  const { error: getTemplateIsError } = useGetUserCourseTemplateQuery(
    { templateId: selectedTemplateId },
    {
      enabled: selectedTemplateId > -1,
      onSuccess: data => {
        if (data.exercises) {
          setUserExercises(convertTemplateExercisesToUsercourseExercises(data.exercises));
        }
        if (data.lessons) {
          setUserLessons(data.lessons);
        } else {
          setUserLessons([]);
        }
        if (data.max_exercises_per_day) {
          changeExercisesPerDay(data.max_exercises_per_day);
        }
        setFunctionalTest(data.functional_test);
        setHealthFormType(data.health_form_type);
      },
      onSettled: () => {
        setSelectedTemplateId(-1);
      },
    }
  );

  const { mutate: deleteTemplateMutate, error: deleteTemplateIsError } = useDeleteUserCourseTemplate({
    onSuccess: () => {
      queryClient.invalidateQueries(GET_USER_COURSE_TEMPLATES_QUERY_KEY);
    },
  });

  const isError =
    courseIsError ||
    templatesIsError ||
    putCourseIsError ||
    getTemplateIsError ||
    createTemplateIsError ||
    deleteTemplateIsError ||
    updateTemplateIsError ||
    markTreatmentIsError;

  // manually refreshing usercourse in order to not replace current edited unsaved usercourse
  useEffect(() => {
    refetchCourseData();
  }, []);

  useEffect(() => {
    if (!courseData || !exercisesPerDay) {
      return;
    }
    const pristine = isSameCourse(courseData, {
      exercises: userExercises,
      lessons: userLessons,
      max_exercises_per_day: exercisesPerDay,
      strategy: "custom",
      functional_test: functionalTest,
      health_form_type: healthFormType || "generic",
    });
    setIsPristine(pristine);
  }, [userExercises, exercisesPerDay, courseData, functionalTest, userLessons, healthFormType]);

  // clear lessons if all exercises are removed
  useEffect(() => {
    if (!userExercises.length && userLessons.length) {
      setUserLessons([]);
    }
  }, [userExercises]);

  useEffect(() => {
    if (userExercises.length > 0 && missingExercises.overridden === false) {
      checkNoExerciseNextWeekWarning(userExercises);
    }
  }, [userExercises]);

  useEffect(() => {
    if (doSave) {
      saveTreatment();
      setDoSave(false);
    }
  }, [doSave]);

  // utility functions
  const checkNoExerciseNextWeekWarning = (exercises: UserCourseExercise[]) => {
    // Determine the highest introduction_protocol_week
    const maxIntroductionWeek = Math.max(
      ...exercises.map(exercise => exercise.introduction_protocol_week),
      0 // Default to 0 if there are no exercises
    );

    // Set a finite `finalWeek` which provides a buffer for calculations
    const finalWeek = maxIntroductionWeek + 52; // Adding a buffer

    // Initialize a Set to track weeks covered by exercises
    const coveredWeeks = new Set<number>();

    // Mark weeks as covered for each exercise's active range
    exercises.forEach(exercise => {
      const endWeek = exercise.end_protocol_week ?? finalWeek; // Use end_protocol_week if defined, else treat as indefinite
      for (let week = exercise.introduction_protocol_week; week <= endWeek; week += 1) {
        coveredWeeks.add(week);
      }
    });

    // Find missing weeks in the range from current patient's week to `finalWeek`
    const weeksWithoutExercises: number[] = [];
    const { week: patientWeek } = patient;
    const patientNextWeek = patientWeek + 1;
    for (let week = patientNextWeek; week <= finalWeek; week += 1) {
      if (!coveredWeeks.has(week)) {
        weeksWithoutExercises.push(week);
      }
    }

    if (weeksWithoutExercises.length > 0) {
      setMissingExercises(prevState => ({ ...prevState, weeks: weeksWithoutExercises }));
    } else {
      setMissingExercises(prevState => ({ ...prevState, weeks: [] }));
    }
  };

  const openExercisePicker = () => {
    setShowExercisePicker(true);
  };

  const openLessonPicker = () => {
    setShowLessonPicker(true);
  };

  const addExercises = (addedExercises: UserCourseLibraryExercises) => {
    setUserExercises((state: UserCourseExercise[]) => {
      // remove exercises not included in the new ones
      const newState = [...state].filter(ue => {
        const match = addedExercises.find(ae => ae.exercise_id === ue.id);
        return match;
      });

      // only add new ones so we dont reset week, level etc.
      const newExercises = addedExercises.filter(ae => {
        const match = !!state.find(ue => ue.id === ae.exercise_id);
        return !match;
      });

      newExercises.forEach((exercise: UserCourseLibraryExercise) => {
        newState.push({
          active: false,
          current_level: 1,
          end_protocol_week: null,
          id: exercise.exercise_id,
          introduction_protocol_week: currentProtocolWeek + 1,
          levels: JSON.parse(JSON.stringify(exercise.levels)),
          premium: exercise.premium,
          target_joints: exercise.target_joints,
        });
      });

      setNewlyAddedExercises(newExercises.map(exercise => exercise.exercise_id));

      return newState;
    });
    setShowExercisePicker(false);
  };

  const addLessons = (addedLessons: UserCourseLesson[]) => {
    setUserLessons((state: UserCourseLesson[]) => {
      // remove exercises not included in the new ones
      const newState = [...state].filter(ul => {
        const match = addedLessons.find(al => al.id === ul.id);
        return match;
      });

      // only add new ones so we dont reset week, level etc.
      const newLessons = addedLessons.filter(al => {
        const match = !!state.find(ul => ul.id === al.id);
        return !match;
      });

      newLessons.forEach((lesson: UserCourseLesson) => {
        newState.push(lesson);
      });

      setNewlyAddedLessons(newLessons.map(lesson => lesson.id));
      setTimeout(() => setNewlyAddedLessons([]), 5000);

      return newState;
    });
    setShowLessonPicker(false);
  };

  const deleteExercise = (id: number) => {
    setUserExercises((state: UserCourseExercise[]) => {
      previousStateRef.current = state.map(exercise => ({ ...exercise }));
      return state.filter(exercise => exercise.id !== id);
    });
  };

  const changeLevel = (id: number, level: number) => {
    setUserExercises((state: UserCourseExercise[]) => {
      const newState = [...state];
      const index = newState.findIndex(exercise => exercise.id === id);
      newState[index].current_level = level;
      return newState;
    });
  };

  const changeWeek = (id: number, week: number) => {
    setUserExercises((state: UserCourseExercise[]) => {
      previousStateRef.current = state.map(exercise => ({ ...exercise }));
      const newState = [...state];
      const index = newState.findIndex(exercise => exercise.id === id);
      if (index !== -1) {
        newState[index] = {
          ...newState[index],
          introduction_protocol_week: week,
        };
      }
      return newState;
    });
  };

  const changeEndWeek = (id: number, week: number | null) => {
    setUserExercises((state: UserCourseExercise[]) => {
      previousStateRef.current = state.map(exercise => ({ ...exercise }));
      const newState = [...state];
      const index = newState.findIndex(exercise => exercise.id === id);
      if (index !== -1) {
        newState[index] = {
          ...newState[index],
          end_protocol_week: week,
        };
      }
      return newState;
    });
  };

  const changeExercisesPerDay = (n: number) => {
    setExercisesPerDay(n);
  };

  const deleteLesson = (id: number) => {
    setUserLessons((state: UserCourseLesson[]) => {
      return state.filter(lesson => lesson.id !== id);
    });
  };

  const clearExercises = () => setUserExercises([]);

  const saveTreatment = async () => {
    // BE issue workaround, don't change unchanged levels
    const trimmedExercises: UserCourseTrimmedExercise[] = removeUnchangedLevels(
      courseData?.exercises || [],
      userExercises
    );

    const includedExercises = trimmedExercises.map(e => {
      const level = e.current_level || 1;
      const { id, title, variation_title } = e.levels[level - 1];
      return {
        title: `${title}${variation_title ? ` (${variation_title})` : ""}`,
        exerciseId: e.id,
        level,
        levelId: id,
      };
    });

    const includedLessons = userLessons.map(({ title, id }) => ({ title, id }));

    const hft = healthFormType || "generic";
    const most_painful_location = patient.most_painful_location?.location || undefined;
    const { treatment_started, ailment } = patient;

    AnalyticsService.track(AnalyticsEvents.USERCOURSE.SAVE_COURSE, {
      patientId,
      includedExercises,
      includedLessons,
      exercisesPerDay,
      functionalTest,
      healthFormType: hft,
      most_painful_location,
      ailment,
      treatment_started,
    });

    const course = createCoursePayload({
      exercises: trimmedExercises,
      lessons: userLessons,
      exercisesPerDay,
      functionalTest,
      healthFormType: hft,
    });

    await putCourseMutate(
      { patientId, course },
      {
        onSuccess: () => {
          if (isRTM(profile)) {
            trackActivity({
              category: "treatment_protocol_changed",
              patient_id: patientId,
              start_time: startTime.current,
              end_time: new Date().toISOString(),
            });
          }
        },
      }
    );
  };

  const saveAndmarkTreatmentReady = async () => {
    await saveTreatment();
    AnalyticsService.track(AnalyticsEvents.USERCOURSE.ACTIVATE_COURSE, { patientId });
    await markTreatmentMutate({ patientId });
  };

  const saveTemplate = async (name: string) => {
    const therapistId = profile.id;

    const course = createCoursePayload({
      exercises: userExercises,
      lessons: userLessons,
      exercisesPerDay,
      functionalTest,
      healthFormType: healthFormType || "generic",
    });
    createTemplateMutate(
      {
        therapistId,
        course: {
          ...course,
          name,
        },
      },
      {
        onSuccess: () => {
          setShowTemplatePopup("none");
        },
      }
    );
  };

  const updateTemplate = async (templateId: number, name: string) => {
    const therapistId = profile.id;

    const course = createCoursePayload({
      exercises: userExercises,
      lessons: userLessons,
      exercisesPerDay,
      functionalTest,
      healthFormType: healthFormType || "generic",
    });

    updateTemplateMutate(
      {
        therapistId,
        templateId,
        course: {
          ...course,
          name,
        },
      },
      {
        onSuccess: () => {
          setShowTemplatePopup("none");
        },
      }
    );
  };

  const deleteTemplate = async (templateId: number) => {
    const therapistId = profile.id;

    deleteTemplateMutate(
      { therapistId, templateId },
      {
        onSuccess: () => {
          setShowTemplatePopup("none");
        },
      }
    );
  };

  const setTemplate = (templateId: number) => {
    setSelectedTemplateId(templateId);
  };

  const offsetTop = usePageContentOffsetTop();

  const clickExercise = (id: number) => {
    const match: UserCourseExercise | undefined = userExercises.find(e => e.id === id);
    if (match) {
      setSelectedExercise(match);
    }
  };

  const changeFunctionalTest = (b: boolean) => {
    setFunctionalTest(b);
  };

  const changeHealthFormType = (hft: HealthFormType) => {
    setHealthFormType(hft);
  };

  const revertExerciseChange = () => {
    if (previousStateRef.current) {
      setUserExercises(previousStateRef.current);
    }
  };

  if (isError) {
    return (
      <LoadingContainer>
        {isError && (
          <Notification variant="danger" style={{ marginBottom: "5px" }}>
            {t("errors.generic")}
          </Notification>
        )}
      </LoadingContainer>
    );
  }

  return (
    <Root>
      <TourPlayer tour={tour} />

      <MobileMenuAdjust $offsetTop={offsetTop}>
        <Summary
          onMarkTreatmentReady={saveAndmarkTreatmentReady}
          onTemplateClick={() => setShowTemplatePopup("save")}
          disableButtons={!userExercises.length}
          onSave={saveTreatment}
          unsavedChanges={!isPristine}
          onChangeView={v => setView(v)}
          view={view}
          exercisesPerDay={exercisesPerDay}
          onChangeExercisesPerDay={changeExercisesPerDay}
          functionalTest={functionalTest}
          onChangeFunctionalTest={changeFunctionalTest}
          healthFormType={healthFormType}
          onChangeHealthFormType={changeHealthFormType}
          healthFormTypeInteractable={false} // Disabled until decided excatly when this should be possible
          enableMismatchWarning={!activatedCourse}
        />
        {userExercises.length < 1 && (
          <Templates
            templates={templatesData}
            onChangeTemplate={setTemplate}
            onDeleteTemplate={deleteTemplate}
            onOpenExercisePicker={openExercisePicker}
          />
        )}

        {view === "exercises" && userExercises.length >= 1 && (
          <>
            <ExercisesList
              exercises={userExercises}
              onOpenExercisePicker={openExercisePicker}
              onClickExercise={clickExercise}
              onDeleteExercise={deleteExercise}
              currentProtocolWeek={currentProtocolWeek}
              onChangeLevel={changeLevel}
              onChangeWeek={changeWeek}
              onChangeEndWeek={changeEndWeek}
              newlyAddedExercises={newlyAddedExercises}
              onClearExercises={() => setShowClearConfirm(true)}
            />
          </>
        )}

        {view === "lessons" && (
          <>
            <LessonsList
              lessons={userLessons}
              onDeleteLesson={deleteLesson}
              onSetLessons={lessons => {
                setUserLessons(lessons);
              }}
              newlyAddedLessons={newlyAddedLessons}
              onOpenLessonPicker={openLessonPicker}
              editable={!activatedCourse}
              setTour={(_tour: Tour | null) => {
                setTour(_tour);
              }}
            />
          </>
        )}
        {showMarkTreatmentReadyConfirmation && (
          <MarkTreatmentReadyConfirmation
            onClose={() => {
              setShowMarkTreatmentReadyConfirmation(false);
              navigate(`/patients/${patientId}/protocol/current`);
            }}
          />
        )}
        {showTemplatePopup !== "none" && (
          <TemplatePopup
            initialView={showTemplatePopup}
            templates={templatesData}
            onSaveTemplate={saveTemplate}
            onUpdateTemplate={updateTemplate}
            onDeleteTemplate={deleteTemplate}
            onSetTemplate={setTemplate}
            onClose={() => {
              setShowTemplatePopup("none");
            }}
          />
        )}
        {selectedExercise && (
          <ExerciseEditPopup
            exercise={selectedExercise}
            currentProtocolWeek={currentProtocolWeek}
            onChangeEndWeek={changeEndWeek}
            onChangeLevel={changeLevel}
            onChangeWeek={changeWeek}
            onClose={() => setSelectedExercise(undefined)}
          />
        )}
        <Drawer open={showExercisePicker} onClose={() => setShowExercisePicker(false)} backgroundColor="#f8f8f8">
          <ExercisePicker
            initialSelectedIds={userExercises.map(e => e.id)}
            onAddExercises={addExercises}
            onClose={() => {
              setShowExercisePicker(false);
            }}
          />
        </Drawer>
        <Drawer open={showLessonPicker} onClose={() => setShowLessonPicker(false)} backgroundColor="#f8f8f8">
          <LessonPicker
            initialSelectedIds={userLessons.map(e => e.id)}
            onAddLessons={addLessons}
            onClose={() => {
              setShowLessonPicker(false);
            }}
          />
        </Drawer>
        {showClearConfirm && (
          <RemoveExercisesConfirmDialog
            onClose={() => setShowClearConfirm(false)}
            onYes={() => {
              clearExercises();
              setShowClearConfirm(false);
            }}
            onNo={() => {
              setShowClearConfirm(false);
            }}
          />
        )}
        {missingExercises.weeks.length > 0 && (
          <NoExercisesConfirmDialog
            weeksMissingExercises={missingExercises.weeks}
            onRevert={revertExerciseChange}
            onIgnoreMissingExercises={() => setMissingExercises({ weeks: [], overridden: true })}
          />
        )}
      </MobileMenuAdjust>
    </Root>
  );
};

export default UserCourseScreen;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

const Root = styled.div`
  background-color: ${props => props.theme.colors.greys.light4};
  height: 100%;
  min-height: 100vh;
`;

const MobileMenuAdjust = styled.div<{ $offsetTop: number }>`
  padding-top: ${props => props.$offsetTop}px;
`;
