import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import invariant from "ts-invariant";

import useGetTreatmentAdherence from "api/hooks/useGetTreatmentAdherence";
import type { Treatment as TreatmentType } from "api/types/Treatment";
import { SmallBlueClock } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import Avatar from "shared/atoms/Avatar";
import Hidden from "shared/atoms/Hidden";
import { HybridCareIcon } from "shared/atoms/HybridCareIcon";
import PremiumIcon from "shared/atoms/PremiumIcon";
import { CurrentPatientContext } from "utils/contexts";
import useLocalizedDate from "utils/date";
import { isRTM } from "utils/patient";

import {
  AfterNameIcon,
  AvatarContainer,
  Info,
  Name,
  NameContainer,
  PatientDetailsContainer,
  Priority,
  TopSection,
} from "../helpers";

import InfoList from "./InfoList";
import PersonalDetails from "./PersonalDetails";
import TreatmentPane from "./TreatmentPane";

const PatientDataList: React.VFC = () => {
  const { profile } = useProfileContext();
  const { patient, previousTreatmentId } = useContext(CurrentPatientContext);
  const { t } = useTranslation();
  const { format, parseISO } = useLocalizedDate();
  const [selectedTreatment, setSelectedTreatment] = useState<TreatmentType>();

  invariant(profile);

  const { data } = useGetTreatmentAdherence(
    { patientID: patient?.id, treatmentID: selectedTreatment?.id },
    { enabled: isRTM(profile) && !!selectedTreatment }
  );

  // TODO: Move into standalone hook
  useEffect(() => {
    const previousTreatment = patient?.treatments.find((tm: TreatmentType) => tm.id === previousTreatmentId);
    const currentTreatment = patient?.treatments.find((tm: TreatmentType) => tm.is_current_treatment === true);
    setSelectedTreatment(previousTreatment || currentTreatment);
  }, [previousTreatmentId, patient?.treatments]);

  const formatDate = (date: string) => {
    // date can be null
    return date ? format(parseISO(date), "PP") : t("common.unknown");
  };

  if (!patient) return null;

  return (
    <>
      <Hidden type="aboveTablet">
        <PersonalDetails
          age={patient.age}
          gender={patient.gender}
          preferredName={patient.preferred_name}
          phoneNumber={patient.phone_number}
          country={patient.country}
        />
        <PatientDetailsContainer data-testid="patient-data-list-mobile">
          <InfoList />
          {selectedTreatment && (
            <TreatmentPane
              adherence={patient?.adherence}
              week={patient?.week}
              isCurrentTreatment={selectedTreatment.is_current_treatment}
              started={formatDate(selectedTreatment.created_at)}
              rtmData={data}
            />
          )}
        </PatientDetailsContainer>
      </Hidden>

      <Hidden type="belowTablet">
        <PatientDetailsContainer data-testid="patient-data-list-desktop">
          <div>
            <Info>
              <AvatarContainer>
                {patient.first_name && patient.last_name && (
                  <Avatar
                    size={60}
                    fontSize={24}
                    id={patient.id}
                    name={`${patient.first_name} ${patient.last_name}`}
                    src={patient.avatars?.large}
                  />
                )}
                <Priority data-testid="prio-icon" $hidden={!patient.prio} />
              </AvatarContainer>
              <NameContainer>
                <TopSection>
                  <Name>{patient.first_name && patient.last_name && `${patient.first_name} ${patient.last_name}`}</Name>
                  {patient.therapist_assignment_role === "substitute_therapist" && (
                    <AfterNameIcon>
                      <SmallBlueClock />
                    </AfterNameIcon>
                  )}
                  <HybridCareIcon ceStructure={patient.ce_structure} size="big" margin="0 0 0 8px" />
                  <PremiumIcon premiumType={patient.premium_type} size="big" margin="0 0 0 8px" />
                </TopSection>
                <PersonalDetails
                  age={patient.age}
                  gender={patient.gender}
                  preferredName={patient.preferred_name}
                  phoneNumber={patient.phone_number}
                  country={patient.country}
                />
              </NameContainer>
            </Info>
            <InfoList />
          </div>
          {selectedTreatment && (
            <TreatmentPane
              adherence={patient?.adherence}
              week={patient?.week}
              isCurrentTreatment={selectedTreatment.is_current_treatment}
              started={formatDate(selectedTreatment.created_at)}
              rtmData={data}
            />
          )}
        </PatientDetailsContainer>
      </Hidden>
    </>
  );
};

export default PatientDataList;
