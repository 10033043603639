import type React from "react";

import { createFilterOptions } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import invariant from "ts-invariant";

import { MUIAutocomplete } from "shared/atoms/inputs";
import { MenuItem, SelectedItem } from "shared/atoms/inputs/MUIMultiselectDropdown";

interface Props {
  name: string;
  label: string;
  optionsFull: Option[];
  singleSelect?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  nonDeletable?: boolean;
}

type Option = {
  label: string;
  value: string | number;
};

export const MultiselectField: React.VFC<Props> = ({
  name,
  label,
  optionsFull,
  singleSelect,
  disabled,
  required,
  error,
  nonDeletable,
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const getLastValue = (values: string[]) => (values.length === 2 && singleSelect ? values.slice(1, 2) : values);
  const options = optionsFull.map(option => option.value.toString());

  return (
    <MUIAutocomplete
      name={name}
      label={label}
      options={options}
      disableCloseOnSelect={!singleSelect}
      required={required}
      disabled={disabled}
      error={error && t("errors.field.required")}
      onChange={(_event, valueArray) => {
        setValue(name, getLastValue(valueArray), {
          shouldDirty: true,
          shouldValidate: true,
        });
      }}
      filterOptions={createFilterOptions({
        stringify: (option: string) => {
          const opt = optionsFull.find(o => o.value === option);
          invariant(opt);
          return `${opt.value} ${opt.label}`;
        },
      })}
      renderOption={(props, option) => {
        return (
          <MenuItem
            label={optionsFull.find(o => o.value === option)?.label ?? ""}
            name={name}
            option={option}
            {...props}
          />
        );
      }}
      renderTag={(valueArray, getTagProps) =>
        valueArray.map((value, i) => (
          <SelectedItem
            key={value}
            label={optionsFull.find(o => o.value === value)?.label ?? ""}
            name={name}
            option={value}
            tagProps={{ ...getTagProps({ index: i }), ...(nonDeletable && { onDelete: undefined }) }}
          />
        ))
      }
    />
  );
};
