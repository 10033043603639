import type React from "react";
import type { RefObject } from "react";

import { InputAdornment, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { validatePlaceholderPresent } from "utils/medicalNotes/placeholderValidation";

import { MUIBorder, MUIError, MUIInput, MUITextInputLabel } from "../StyledInputs";

interface Props {
  readonly autoComplete?: "on" | "off";
  readonly className?: string;
  readonly disabled?: boolean;
  readonly error?: string;
  readonly instruction?: string;
  readonly label?: string;
  readonly max?: number;
  readonly min?: number;
  readonly step?: number;
  readonly multiline?: boolean;
  /* also takes on the role of id, and testid for the label */
  readonly name: string;
  readonly placeholder?: string;
  readonly required?: boolean;
  readonly type?: "text" | "email" | "password" | "number" | "tel";
  // field not registered by react-hook-forms, value omitted from formdata
  readonly unregistered?: boolean;
  readonly value?: string | number | null;
  readonly onUpdate?: (value: string) => void;
  readonly startAdornment?: React.ReactNode;
  readonly endAdornment?: React.ReactNode;
  readonly autoFocus?: boolean;
  readonly inputRef?: RefObject<HTMLDivElement>;
}

const MUITextInput: React.VFC<Props> = ({
  autoComplete = "off",
  className,
  disabled,
  error,
  instruction = null,
  label = "",
  max,
  min,
  step = 1,
  multiline,
  name,
  placeholder = "",
  required,
  type = "text",
  unregistered,
  value = "",
  onUpdate,
  startAdornment,
  endAdornment,
  autoFocus = false,
  inputRef,
}) => {
  const { control, resetField, getFieldState, getValues } = useFormContext();
  const InputProps = {
    inputProps: { ...(type === "number" ? { min, max, step } : {}) },
    startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
    endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
  };

  const validate = required
    ? {
        whiteSpace: (v: string) => RegExp(/\S/).test(v),
        placeholderPresent: (v: string) => validatePlaceholderPresent(v),
      }
    : undefined;

  const rules = { required, min, max, validate };

  // Reset field state.
  const onBlur = () => {
    if (!onUpdate) return;
    const state = getFieldState(name);
    if (!state.isDirty) return;
    const defaultValue = getValues(name);
    resetField(name, { defaultValue, keepError: true });
    onUpdate(defaultValue);
  };

  return (
    <Container className={className}>
      {unregistered ? (
        <StyledTextField
          id={name}
          name={name}
          data-testid={`input-${name}`}
          type={type}
          label={label}
          multiline={multiline}
          disabled={disabled}
          error={!!error}
          helperText={error || instruction}
          autoComplete={autoComplete}
          variant="outlined"
          fullWidth
          defaultValue={value}
          InputProps={InputProps}
          onBlur={onBlur}
          placeholder={placeholder}
          $hasStartAdornment={Boolean(startAdornment)}
          $hasPlaceholder={Boolean(placeholder)}
          autoFocus={autoFocus}
          inputRef={inputRef}
        />
      ) : (
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field }) => (
            <StyledTextField
              {...field}
              value={field.value ?? ""}
              id={name}
              name={name}
              data-testid={`input-${name}`}
              type={type}
              label={label}
              multiline={multiline}
              disabled={disabled}
              required={required}
              error={!!error}
              helperText={error || instruction}
              autoComplete={autoComplete}
              variant="outlined"
              fullWidth
              InputProps={InputProps}
              InputLabelProps={{ required: false }} // hide * from required field label
              onBlur={onBlur}
              placeholder={placeholder}
              $hasStartAdornment={Boolean(startAdornment)}
              $hasPlaceholder={Boolean(placeholder)}
              autoFocus={autoFocus}
              inputRef={inputRef}
            />
          )}
        />
      )}
    </Container>
  );
};

const StyledTextField = styled(TextField)<{ $hasStartAdornment?: boolean; $hasPlaceholder?: boolean }>`
  ${MUIInput}
  .MuiOutlinedInput-root {
    padding-left: ${({ $hasStartAdornment }) => $hasStartAdornment && "12px"};

    .MuiOutlinedInput-input {
      padding: ${({ $hasPlaceholder }) => ($hasPlaceholder ? "15px 12px 15px 0" : "23px 12px 7px")};
      padding-left: ${({ $hasStartAdornment }) => $hasStartAdornment && "0"};
    }
  }
  ${MUIBorder}
  ${MUITextInputLabel}
  ${MUIError}

  input::-webkit-inner-spin-button {
    margin-top: -12px;
    margin-bottom: 4px;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-top: 0;
`;

export default MUITextInput;
