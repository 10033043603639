/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useRef, useState } from "react";

import { ClickAwayListener, Popper } from "@mui/material";
import type { BaseEmoji } from "emoji-mart";
import { Picker } from "emoji-mart";
import type { ChangeHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { EmojiIcon } from "assets";

import QuickReplies from "../QuickReplies";
import { StyledLabel, StyledMultilineInput } from "../StyledInputs";

const HelperText = styled.div<{ error?: boolean; counter?: boolean }>`
  display: flex;
  flex-grow: 1;
  color: ${props => (props.error ? props.theme.colors.tomato : props.theme.colors.greys.dark)};
  ${props => ({ ...props.theme.font.caption })};
  padding-left: 2px;
  padding-right: 2px;
  justify-content: ${props => (props.counter ? "flex-end" : "flex-start")};
`;

const Container = styled.div<{ className?: string; minHeight?: string; $padding: string }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  padding: ${props => props.$padding};
  ${props => props.minHeight && `min-height: ${props.minHeight};`}
`;

const Wrapper = styled.div<{
  $disabled: boolean;
  $readOnly: boolean;
  $error: boolean;
  $inFocus: boolean;
  $noBorder: boolean;
}>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border: 2px solid ${props => (props.$error ? props.theme.colors.tomato : props.theme.colors.greys.silver)};
  border: ${props =>
    props.$noBorder
      ? "none"
      : `2px solid ${props.$error ? props.theme.colors.tomato : props.theme.colors.greys.silver}`};
  ${props =>
    props.$inFocus &&
    `
    background-color: ${props.theme.colors.white};
    border: ${props.$noBorder ? "none" : `2px solid ${props.theme.colors.secondary.base}`};
  `}
  ${props =>
    (props.$disabled || props.$readOnly) &&
    `
    border: ${props.$noBorder ? "none" : `2px solid ${props.theme.colors.greys.silver}`};
    background-color: ${props.theme.colors.greys.light2};
  `}
  border-radius: ${props => props.theme.borderRadius.basic};
`;

const EmojiPoppover = styled(Popper)`
  z-index: ${props => props.theme.zIndex.aboveMessage};
`;

const EmojiContainer = styled.div<{ $showQuickReplies: boolean }>`
  height: 24px;
  width: 24px;
  cursor: pointer;
  ${props => props.theme.belowMobileBreakpoint} {
    display: none;
  }
  padding: 4px;
`;

const ExtrasContainer = styled.div<{ $extrasOffset: string }>`
  display: flex;
  align-items: center;
  margin-left: ${props => props.$extrasOffset};
  min-height: 32px;
  padding: 5px;
`;

interface TextAreaProps {
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  placeholder?: string;
  required?: boolean;
  form?: string;
  name?: string;
  label?: string;
  instruction?: string;
  error?: string;
  rows: number;
  expandToHeight?: number;
  dataTestId?: string;
  onChange?: ChangeHandler;
  onValueUpdate?: (value: string) => void;
  showQuickReplies?: boolean;
  margin?: string;
  padding?: string;
  scrollbarVisible?: boolean;
  externalRef?: React.MutableRefObject<HTMLTextAreaElement | undefined>;
  emojiPicker?: boolean;
  noBorder?: boolean;
  extrasOffset?: string;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const {
    className = undefined,
    disabled = false,
    readOnly = false,
    maxLength = undefined,
    placeholder = "",
    required = false,
    form = undefined,
    name = undefined,
    label = undefined,
    instruction = undefined,
    error = undefined,
    dataTestId = undefined,
    // sets the initial size of the textarea
    rows,
    // number - if passed, makes textarea expandable up to this height
    expandToHeight = undefined,
    onChange = () => {},
    onValueUpdate = undefined,
    showQuickReplies = false,
    margin = "0",
    padding = "10px 0",
    scrollbarVisible = true,
    externalRef,
    emojiPicker = false,
    noBorder = false,
    extrasOffset = "4px",
  } = props;
  const { t } = useTranslation();
  const areaRef = useRef<HTMLTextAreaElement>();
  const [value, setValue] = useState("");
  const [inFocus, setInFocus] = useState(false);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState<EventTarget | undefined>(undefined);
  const emojiPickerOpen = Boolean(emojiAnchorEl);

  // Update height of textarea based on input
  useEffect(() => {
    if (areaRef.current?.value === "") {
      areaRef.current.style.height = "auto";
    }
    if (expandToHeight && areaRef.current?.value) {
      areaRef.current.style.height = "auto";
      areaRef.current.style.height = `${Math.min(areaRef.current.scrollHeight, expandToHeight)}px`;
    }
  }, [areaRef.current, areaRef.current?.value]);

  const leftText = <HelperText error={!!error}>{error || instruction}</HelperText>;

  const updateTextAreaHeight = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (expandToHeight) {
      // NOTE: Setting the height to auto enables shrinking when removing characters
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = "auto";
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${Math.min(event.target.scrollHeight, expandToHeight)}px`;
    }
    setValue(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    onChange(event);
    updateTextAreaHeight(event);
  };

  const handleQuickReply = (quickReply: string): void => {
    const messageArea = areaRef.current;
    let cursorPosition = messageArea?.selectionStart || 0;
    const currentReply = messageArea?.value;
    const messageLength = currentReply?.length || 0;

    // Append data in the end in Safari also (cursorPosition is 0 until user clicks inside textarea)
    if (cursorPosition === 0) {
      cursorPosition = messageLength;
    }

    const updatedReply =
      currentReply?.slice(0, cursorPosition) + quickReply + currentReply?.slice(cursorPosition, currentReply?.length);

    if (onValueUpdate) {
      onValueUpdate(updatedReply);
    }
  };

  const onEmojiClicked = (emoji: BaseEmoji) => {
    handleQuickReply(emoji.native);
    handleEmojiClose();
  };

  const handleEmojiClose = () => {
    setEmojiAnchorEl(undefined);
  };

  const handleEmojiClick = (event: React.MouseEvent) => {
    if (event.currentTarget !== null) {
      setEmojiAnchorEl(event.currentTarget);
    }
  };

  return (
    <Container className={className} $padding={padding}>
      {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
      <Wrapper
        $disabled={disabled}
        $error={!!error}
        $readOnly={readOnly}
        $inFocus={inFocus || document.activeElement === areaRef.current}
        $noBorder={noBorder}
      >
        <StyledMultilineInput
          data-testid={dataTestId || "text-area"}
          ref={event => {
            // NOTE: needed to be able to pass the register function for react-hook-form
            if (ref && typeof ref === "function") ref(event);
            // eslint-disable-next-line no-param-reassign
            else if (ref && typeof ref !== "function") ref.current = event;
            areaRef.current = event || undefined;

            // Ref cooming from MessageInput.tsx
            if (externalRef) {
              // eslint-disable-next-line no-param-reassign
              externalRef.current = event || undefined;
            }
          }}
          onChange={handleChange}
          onBlur={() => setInFocus(false)}
          onFocus={() => setInFocus(true)}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder={placeholder}
          required={required}
          form={form}
          name={name}
          id={name}
          error={error}
          rows={rows}
          $size="large"
          $margin={margin}
          $scrollbarVisible={scrollbarVisible}
        />
        <ExtrasContainer $extrasOffset={extrasOffset}>
          {emojiPicker && !disabled && (
            <>
              <EmojiContainer
                $showQuickReplies={showQuickReplies}
                onClick={handleEmojiClick}
                title={t("messages.tooltip.emojis")}
              >
                <EmojiIcon data-testid="emoji-button" />
              </EmojiContainer>
              {emojiPickerOpen && (
                <EmojiPoppover open={emojiPickerOpen} anchorEl={emojiAnchorEl as Element} placement="top-start">
                  <ClickAwayListener onClickAway={handleEmojiClose}>
                    <Picker
                      showPreview={false}
                      showSkinTones={false}
                      perLine={6}
                      native
                      onClick={(emoji: BaseEmoji) => onEmojiClicked(emoji)}
                    />
                  </ClickAwayListener>
                </EmojiPoppover>
              )}
            </>
          )}
          {showQuickReplies && !disabled && !readOnly && <QuickReplies onQuickReplySelected={handleQuickReply} />}
        </ExtrasContainer>
      </Wrapper>
      {(error || instruction) && leftText}
      {maxLength && <HelperText counter={!!maxLength}>{`${value.length} / ${maxLength}`}</HelperText>}
    </Container>
  );
});

export default TextArea;
