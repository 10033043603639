import type React from "react";
import { useContext, useState } from "react";

import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";

import useGetTreatmentActivityLogsPatient from "api/hooks/useGetTreatmentActivityLogsPatient";
import { usePutTreatmentActivityLog } from "api/hooks/usePutTreatmentActivityLog";
import type { LogEntry } from "api/schemas/TreatmentActivityLog";
import { ButtonContainer, PopupContent } from "routes/admin-billing/views/AdminBillingView";
import { Header } from "routes/calendar/components/EventDetails/styles";
import { Accordion, AccordionItem } from "shared/atoms/Accordion";
import { BaseButton } from "shared/atoms/BaseButton";
import { Notification } from "shared/atoms/Notification";
import Popup from "shared/atoms/Popup";
import { Table, TableHeader } from "shared/molecules/Table";
import { CurrentPatientContext } from "utils/contexts";
import useLocalizedDate from "utils/date";
import { reportError } from "utils/errorReporting";

import { ActivityLogForm } from "../components/ActivityLogForm";

type Dialog = {
  id: number | null;
  show: boolean;
  status: "accepted" | "discarded" | null;
};

const initialDialogState: Dialog = {
  id: null,
  show: false,
  status: null,
};

const ActivityLog: React.VFC = () => {
  const { t } = useTranslation();
  const { format } = useLocalizedDate();
  const theme = useTheme();
  const { patient, patientError } = useContext(CurrentPatientContext);
  const [showAddActivityForm, setShowAddActivityForm] = useState(false);
  const [showDialog, setShowDialog] = useState<Dialog>(initialDialogState);
  const [selectedActivityLog, setSelectedActivityLog] = useState<LogEntry | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { data: activityLogsPatient, refetch } = useGetTreatmentActivityLogsPatient(patient.id);
  const { mutateAsync, isLoading } = usePutTreatmentActivityLog();

  const logs = activityLogsPatient?.logs || {};
  const totals = activityLogsPatient?.total || {};

  const updateActivityLog = async () => {
    try {
      if (showDialog.id && showDialog.status) {
        mutateAsync(
          { id: showDialog.id, data: { status: showDialog.status } },
          {
            onSuccess: () => {
              setShowDialog(initialDialogState);
              refetch();
            },
            onError: () => {
              setError(t("errors.generic"));
            },
          }
        );
      }
    } catch (e) {
      setError(t("errors.generic"));
      if (e instanceof Error || typeof e === "string") {
        reportError("AvailabilityForm.tsx", e);
      }
    }
  };

  return patientError ? (
    <ErrorContainer>
      <Notification variant="danger" style={{ margin: "10px" }}>
        {t("errors.generic")}
      </Notification>
    </ErrorContainer>
  ) : (
    <Container>
      <Table>
        <thead>
          <StyledTableHeader title={t("patients.menu.activity_log")}>
            <BaseButton
              text=""
              onClick={() => setShowAddActivityForm(true)}
              variant="tertiary"
              icon="circle-plus"
              iconPlacement="end"
            />
          </StyledTableHeader>
        </thead>
        <tbody>
          <Accordion>
            {logs &&
              Object.keys(logs).length > 0 &&
              Object.keys(logs)?.map(month => {
                const totalDuration = totals?.[month]?.duration;

                return (
                  <StyledAccordionItem
                    key={month}
                    header={format(new Date(month), "MMMM yyyy")}
                    content={
                      <List>
                        <ListItem>
                          <BrightCell>{t("patients.activity_log.table.date")}</BrightCell>
                          <DarkCell>{t("patients.activity_log.table.activity")}</DarkCell>
                          <BrightCell>{t("patients.activity_log.table.time")}</BrightCell>
                          <BrightCell>{t("patients.activity_log.table.accept")}</BrightCell>
                          <BrightCell>{t("patients.activity_log.table.discard")}</BrightCell>
                          <BrightCell>{t("patients.activity_log.table.edit")}</BrightCell>
                        </ListItem>
                        {logs[month]?.map(activity => {
                          const isEditable = activity.status === "pending";

                          return (
                            <ListItem key={activity.id}>
                              <BrightCell>{format(new Date(activity.completed_at), "yyyy-MM-dd")}</BrightCell>
                              <DarkCell>{activity.category.description}</DarkCell>
                              <BrightCell>
                                {t("patients.activity_log.table.duration", { duration: activity.duration / 60 })}
                              </BrightCell>
                              <BrightCell>
                                {isEditable && (
                                  <ButtonContainer
                                    onClick={e => {
                                      e.stopPropagation();
                                      setShowDialog({ id: activity.id, show: true, status: "accepted" });
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faCircleCheck}
                                      color={theme.colors.redesign.g100}
                                      style={{ height: "18px", width: "18px" }}
                                    />
                                  </ButtonContainer>
                                )}
                              </BrightCell>
                              <BrightCell>
                                {isEditable && (
                                  <ButtonContainer
                                    onClick={e => {
                                      e.stopPropagation();
                                      setShowDialog({ id: activity.id, show: true, status: "discarded" });
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faCircleXmark}
                                      color={theme.colors.redesign.r100}
                                      style={{ height: "18px", width: "18px" }}
                                    />
                                  </ButtonContainer>
                                )}
                              </BrightCell>
                              <BrightCell>
                                {isEditable && (
                                  <ButtonContainer
                                    onClick={e => {
                                      e.stopPropagation();
                                      setSelectedActivityLog(activity);
                                      setShowAddActivityForm(true);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faPen}
                                      color={theme.colors.black}
                                      style={{ height: "18px", width: "18px" }}
                                    />
                                  </ButtonContainer>
                                )}
                              </BrightCell>
                            </ListItem>
                          );
                        })}
                        <ListItem>
                          <DarkCell>{t("patients.activity_log.table.summary")}</DarkCell>
                          <DarkCell />
                          <DarkCell>
                            {t("patients.activity_log.table.duration", {
                              duration: totalDuration ? totalDuration / 60 : 0,
                            })}
                          </DarkCell>
                        </ListItem>
                      </List>
                    }
                  />
                );
              })}
          </Accordion>
        </tbody>
      </Table>

      {showAddActivityForm && (
        <ActivityLogForm
          selectedActivityLog={selectedActivityLog}
          onClose={() => {
            setShowAddActivityForm(false);
            setSelectedActivityLog(null);
          }}
          refetch={refetch}
        />
      )}

      {showDialog.show && showDialog.id && showDialog.status && (
        <Popup fullBgOpacity onClickOutside={() => setShowDialog(initialDialogState)} showCloseButton>
          <PopupContent>
            <Header>
              {showDialog.status === "accepted"
                ? t("admin_billing.mark_as_completed")
                : t("admin_billing.mark_as_discarded")}
            </Header>
            <BaseButton
              text={t("buttons.yes")}
              onClick={() => updateActivityLog()}
              disabled={isLoading}
              loading={isLoading}
              uppercase
              style={{ marginBottom: "20px" }}
            />
            <BaseButton
              text={t("buttons.cancel")}
              onClick={() => setShowDialog(initialDialogState)}
              disabled={isLoading}
              variant="tertiaryBlue"
              uppercase
            />
            {error && (
              <Notification variant="danger" style={{ marginTop: "16px" }}>
                {error}
              </Notification>
            )}
          </PopupContent>
        </Popup>
      )}
    </Container>
  );
};

const ErrorContainer = styled.div`
  margin: 0 auto ${props => props.theme.spacing.S_20};
  max-width: 1000px;
  padding: ${props => props.theme.spacing.S_20};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
`;

const StyledTableHeader = styled(TableHeader)`
  h6 {
    margin: 0 8px 0 0;
  }
`;

const StyledAccordionItem = styled(AccordionItem)`
  .header {
    background: ${({ theme }) => theme.colors.greys.light4};
  }

  .content {
    padding: 0;
  }
`;

const List = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: flex;
  padding: 12px 16px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.redesign.db20};
  }
`;

const Cell = styled.div`
  display: flex;
  flex: 1;
  font-size: 14px;
`;

const BrightCell = styled(Cell)`
  color: ${({ theme }) => theme.colors.redesign.db60};
`;

const DarkCell = styled(Cell)`
  color: ${({ theme }) => theme.colors.redesign.db90};
`;

export default ActivityLog;
