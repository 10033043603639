import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import useGetTreatmentActivityLogs from "api/hooks/useGetTreatmentActivityLogs";
import type { TreatmentActivityLog } from "api/schemas/TreatmentActivityLog";
import { Table, TableCell, TableHeader, TableRow, TableSort } from "shared/molecules/Table";

import { Name, NameWrapper, NameWrapperInner, TypeWrapper } from "../MedicalNotesTable/styles";

export const ActivityLogsTable: React.VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetTreatmentActivityLogs();
  const [activityLogs, setActivityLogs] = useState<TreatmentActivityLog[]>([]);

  const columns = [
    t("dashboard.tables.activity_logs.labels.patient"),
    t("dashboard.tables.activity_logs.labels.count"),
  ];
  const [sort, setSort] = useState({
    selectedColumn: columns[0],
    descending: false,
  });

  const compare = (a: TreatmentActivityLog, b: TreatmentActivityLog) => {
    let x: string | number | Date = "";
    let y: string | number | Date = "";
    switch (sort.selectedColumn) {
      case t("dashboard.tables.activity_logs.labels.patient"):
        x = a.full_name.toLowerCase();
        y = b.full_name.toLowerCase();
        break;
      case t("dashboard.tables.activity_logs.labels.count"):
        x = a.count;
        y = b.count;
        break;
      default:
        break;
    }
    if (x === y) {
      x = a.patient_id;
      y = b.patient_id;
    }
    if (sort.descending) {
      return y < x ? 1 : -1;
    }
    return x < y ? 1 : -1;
  };

  useEffect(() => {
    if (data) {
      setActivityLogs(data.sort(compare));
    }
  }, [data, sort]);

  const onSort = (clickedColumn: string) => {
    if (sort.selectedColumn === clickedColumn) {
      setSort({ selectedColumn: clickedColumn, descending: !sort.descending });
    } else {
      setSort({ selectedColumn: clickedColumn, descending: true });
    }
  };

  return (
    <>
      <StyledTable>
        <thead>
          <TableHeader colSpan={columns.length} title={t("dashboard.tables.activity_logs.title")} />
          {activityLogs.length > 0 && (
            <TableSort columns={columns} sortBy={sort.selectedColumn} descending={sort.descending} onSort={onSort} />
          )}
        </thead>
        <tbody>
          {activityLogs.length > 0 &&
            activityLogs.map(log => {
              return (
                <TableRow
                  key={log.patient_id}
                  onClick={() => {
                    navigate(`/patients/${log.patient_id}/activity_log`);
                  }}
                >
                  <NameWrapper>
                    <NameWrapperInner>
                      <Name $label_state={false}>{log.full_name}</Name>
                    </NameWrapperInner>
                  </NameWrapper>
                  <TypeWrapper>{log.count}</TypeWrapper>
                </TableRow>
              );
            })}
          {activityLogs.length <= 0 && (
            <TableRow hoverEnabled={false}>
              <TableCell>{t("dashboard.tables.activity_logs.no_logs") as string}</TableCell>
            </TableRow>
          )}
        </tbody>
      </StyledTable>
    </>
  );
};

const StyledTable = styled(Table)`
  display: block;
  overflow-x: auto;
`;
