import { z } from "zod";

import { ModeSchema, PurposeSchema } from "./HcpService";

const AvailabilitySchema = z.enum(["available", "unavailable"]);
const RecurrenceSchema = z.enum(["none", "weekly"]);
const ProfessionSchema = z.enum([
  "occupational_therapist",
  "physical_therapist",
  "speech_therapist",
  "wellness_therapist",
]);

const HealthCareProfessionalSchema = z.object({
  id: z.number(),
  full_name: z.string(),
  profession: ProfessionSchema,
});

const BookingIntervalPayloadSchema = z.object({
  availability: AvailabilitySchema,
  end_time: z.string(),
  note: z.string().nullable().optional(),
  recurrence: RecurrenceSchema,
  service_id: z.number().optional(),
  service_ids: z.array(z.number()).optional(),
  start_time: z.string(),
});

const HealthCareServiceSchema = z
  .object({
    id: z.number(),
    mode: ModeSchema,
    purpose: PurposeSchema,
    name: z.string(),
  })
  .nullable();

export const BookingIntervalResponseSchema = z.object({
  availability: AvailabilitySchema,
  end_time: z.string(),
  health_care_professional: HealthCareProfessionalSchema,
  health_care_service: HealthCareServiceSchema,
  id: z.number(),
  note: z.string().nullable(),
  recurrence: RecurrenceSchema,
  start_time: z.string(),
});

const PostBookingIntervalArgsSchema = z.object({
  data: BookingIntervalPayloadSchema,
  hcProfID: z.number(),
});

const PostManagerBookingIntervalArgsSchema = PostBookingIntervalArgsSchema.extend({
  managerID: z.number(),
});

export type Availability = z.infer<typeof AvailabilitySchema>;
export type Recurrence = z.infer<typeof RecurrenceSchema>;
export type BookingIntervalPayload = z.infer<typeof BookingIntervalPayloadSchema>;
export type HealthCareService = z.infer<typeof HealthCareServiceSchema>;
export type BookingIntervalResponse = z.infer<typeof BookingIntervalResponseSchema>;
export type PostBookingIntervalArgs = z.infer<typeof PostBookingIntervalArgsSchema>;
export type PostManagerBookingIntervalArgs = z.infer<typeof PostManagerBookingIntervalArgsSchema>;

const PutBookingIntervalArgsSchema = z.object({
  bookingIntervalID: z.number(),
  data: BookingIntervalPayloadSchema,
  hcProfID: z.number(),
});

export type PutBookingIntervalArgs = z.infer<typeof PutBookingIntervalArgsSchema>;

const DeleteBookingIntervalArgsSchema = z.object({
  bookingIntervalID: z.number(),
  hcProfID: z.number(),
});

export type DeleteBookingIntervalArgs = z.infer<typeof DeleteBookingIntervalArgsSchema>;
