import { useState } from "react";

import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { isValid } from "date-fns";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { BackIcon } from "assets";
import { useCalendarContext } from "contexts/CalendarContext";
import { Calendar } from "routes/calendar/components/Calendar";
import type { SetSelectArgs } from "routes/calendar/components/Calendar/Calendar";
import { Filters } from "routes/calendar/components/Calendar/components/Filters";
import { BookAppointmentForm } from "routes/calendar/components/Forms/BookAppointmentForm";
import { CloseButton, StyledHeader } from "routes/calendar/components/Forms/Header";

interface Props {
  closeCallback: (value: boolean) => void;
}

export const BookingForm: React.VFC<Props> = ({ closeCallback }) => {
  const { patientId } = useParams<{ patientId?: string }>();
  const { t } = useTranslation();
  const theme = useTheme();
  const { selectedDate } = useCalendarContext();
  const [step, setStep] = useState<"calendar" | "form">("calendar");
  const [initialDate, setInitialDate] = useState<SetSelectArgs>();
  const [defaultServiceID, setDefaultServiceID] = useState<number | undefined>();

  const isCalendarActive = step === "calendar";
  const isFormActive = step === "form";

  return (
    <Container>
      <OverflowContainer>
        <Header $isCalendarActive={isCalendarActive}>
          {isCalendarActive ? (
            <>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StyledDatePicker
                  name="date"
                  format="yyyy-MM-dd"
                  value={initialDate?.start}
                  onChange={input => {
                    if (input && isValid(input)) {
                      setInitialDate({ start: input, end: input });
                    }
                  }}
                  disablePast
                />
              </LocalizationProvider>

              <StyledFilters />
            </>
          ) : (
            <BackButton onClick={() => setStep("calendar")}>
              <BackIcon />
            </BackButton>
          )}

          {isFormActive && t("booking.forms.header")}

          <CloseButton onClick={() => closeCallback(false)}>
            <FontAwesomeIcon
              icon={faClose}
              color={theme.colors.redesign.db90}
              style={{ height: "24px", width: "24px" }}
            />
          </CloseButton>
        </Header>

        {isCalendarActive && (
          <StyledCalendar
            customOnSelect={serviceID => {
              setDefaultServiceID(serviceID);
              setStep("form");
            }}
            lockedView="timeGridDay"
            initialDate={initialDate}
            navigateToCalendar
          />
        )}

        {isFormActive && (
          <BookAppointmentForm
            defaultServiceID={defaultServiceID}
            onClose={() => {
              closeCallback(true);
            }}
            selectedDate={selectedDate}
            selectedPatient={patientId}
          />
        )}
      </OverflowContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 400px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background: #fff;

  ${props => props.theme.belowBreakpoint} {
    width: 100vw;
  }
`;

// Safari bug: separate position and overflow to allow children with position fixed to ignore overflow
const OverflowContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

const Header = styled(StyledHeader)<{ $isCalendarActive: boolean }>`
  ${({ $isCalendarActive }) =>
    $isCalendarActive &&
    `
    padding: 16px;
  `}
`;

const BackButton = styled.div`
  cursor: pointer;
`;

const StyledDatePicker = styled(DatePicker)`
  .MuiInputBase-input {
    width: 0;
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }

  .MuiInputAdornment-positionEnd {
    margin-left: 0;
  }

  .MuiButtonBase-root {
    padding: 0;
  }
`;

const StyledFilters = styled(Filters)`
  right: 56px;
`;

const StyledCalendar = styled(Calendar)`
  margin: 0 !important;

  .fc-header-toolbar {
    width: calc(100% - 68px);
    margin-bottom: 0 !important;
    justify-content: center;
    position: absolute;
    top: 36px;
    left: 68.5px;
    right: 0;
    z-index: 2;
  }

  ${props => props.theme.belowBreakpoint} {
    width: 100vw;

    .fc-header-toolbar {
      align-items: center !important;
      margin-top: 16px !important;
      padding: 0 8px;
      top: 20px;

      & > * > :not(:first-child) {
        margin-left: 8px !important;
      }
    }
  }
`;
