import type { EventContentArg } from "@fullcalendar/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Tooltip } from "shared/atoms/Tooltip";
import useLocalizedDate from "utils/date";

import { AppointmentState } from "../../EventDetails/AppointmentDetails/AppointmentState";
import { AppointmentStateIcon } from "../../shared/AppointmentStateIcon";
import { Initials } from "../../shared/Initials";

import { isAppointmentConfirmed, isAppointmentDisabled } from "./transformAppointments";

type Participant = {
  id: number;
  full_name: string | null;
};

interface Props {
  eventContentArgs: EventContentArg;
  isAdmin: boolean;
  isDayView: boolean;
}

export const EventContent: React.VFC<Props> = (args: Props) => {
  const { t } = useTranslation();
  const { format } = useLocalizedDate();

  const {
    eventContentArgs: {
      event: {
        start,
        end,
        title,
        extendedProps: { participants, payer, payment_method, personnel, state, type },
      },
    },
    isAdmin,
    isDayView,
  } = args;

  const isConfirmed = isAppointmentConfirmed({ paymentMethod: payment_method, payerName: payer?.payer_name, state });
  const isDisabled = isAppointmentDisabled(state);

  const getTitle = () => {
    if (isAdmin) {
      // TODO: fix types
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `(${t(`settings.profile_information.profession.${personnel.profession}`)}) ${
        personnel.full_name
      }\n${title}\n${start && format(start, "p")} - ${end && format(end, "p")} ${
        participants?.map((participant: Participant) => participant.full_name).join("\n") || ""
      }`;
    }

    return `${title}\n${start && format(start, "p")} - ${end && format(end, "p")} ${
      participants?.map((participant: Participant) => participant.full_name).join("\n") || ""
    }`;
  };

  const renderContent = () => {
    return (
      <Container $isDayView={isDayView} $isDisabled={isDisabled}>
        <div className="event-content">
          <div className="service">
            {!isConfirmed && (
              <AppointmentStateContainer>
                <ReservedLabel>{t("booking.event_details.not_confirmed")}</ReservedLabel>
              </AppointmentStateContainer>
            )}
            {isDisabled && state !== "completed" && state !== "no_show" && (
              <AppointmentStateContainer>
                <AppointmentState state={state} />
              </AppointmentStateContainer>
            )}
            {isAdmin && personnel && personnel.profession && personnel.full_name && (
              <ProfessionalInfo>
                <StyledProfession $isDayView={isDayView} $isDisabled={isDisabled}>{`(${t(
                  // TODO: fix types
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  `settings.profile_information.profession.${personnel.profession}_short`
                )})`}</StyledProfession>
                <StyledInitials fullName={personnel.full_name} $isDayView={isDayView} $isDisabled={isDisabled} />
              </ProfessionalInfo>
            )}
            <AppointmentStateIcon state={state} />
            {title}
          </div>
          <div className="participant">
            {participants?.map((participant: Participant) => participant.full_name).join(", ")}
          </div>
        </div>
      </Container>
    );
  };

  return (
    <>
      {type === "appointment" || type === "booking_interval" ? (
        <Tooltip
          placement="top"
          title={<div style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>{getTitle()}</div>}
        >
          {renderContent()}
        </Tooltip>
      ) : (
        renderContent()
      )}
    </>
  );
};

const Container = styled.div<{ $isDayView: boolean; $isDisabled: boolean }>`
  height: 100%;
  padding-left: 4px;
  color: ${props => (props.$isDisabled ? props.theme.colors.redesign.db60 : props.theme.colors.redesign.db90)};
  overflow: hidden;

  .event-content {
    display: flex;
    flex-wrap: wrap;
  }

  .service {
    display: flex;
    align-items: center;
    margin-right: ${({ $isDayView }) => ($isDayView ? "16px" : "0")};
    margin-bottom: ${({ $isDayView }) => ($isDayView ? "0" : "4px")};
    font-size: 12px;
    white-space: nowrap;
  }

  .participant {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
  }
`;

const AppointmentStateContainer = styled.span`
  font-weight: 500;
  margin-right: 4px;
`;

const ProfessionalInfo = styled.div`
  display: flex;
`;

const StyledProfession = styled.div<{ $isDayView: boolean; $isDisabled: boolean }>`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: ${props => (props.$isDisabled ? props.theme.colors.redesign.db60 : props.theme.colors.redesign.db90)};
  margin-right: ${({ $isDayView }) => ($isDayView ? "8px" : "4px")};
`;

const StyledInitials = styled(Initials)<{ $isDayView: boolean; $isDisabled: boolean }>`
  color: ${props => (props.$isDisabled ? props.theme.colors.redesign.db60 : props.theme.colors.redesign.db90)};
  border-color: ${props => (props.$isDisabled ? props.theme.colors.redesign.db60 : props.theme.colors.redesign.db90)};
  margin-right: ${({ $isDayView }) => ($isDayView ? "8px" : "4px")};
`;

const ReservedLabel = styled.div`
  text-transform: uppercase;
`;
