import type { DeepPartial } from "react-hook-form";

import type { CareEvent, CareEventStructuredFields } from "../api/models/CareEvent";

import type { DateString } from "./DateString";

export type CareEventSubset = DeepPartial<CareEvent>;

type RootField = keyof Omit<CareEvent, "structured_fields">;
export type StructuredField = keyof CareEventStructuredFields;
export type StructuredFieldPath<F extends StructuredField = StructuredField> = `structured_fields.${F}`;

export type CareEventFieldPath = RootField | StructuredFieldPath;

export const MedicalNoteTypes = {
  APPOINTMENT: "APPOINTMENT",
  CHAT: "CHAT",
  DIAGNOSIS_THERAPIST: "DIAGNOSIS_THERAPIST",
  DISCHARGE: "DISCHARGE",
  DISCHARGE_TO_SELFCARE: "DISCHARGE_TO_SELFCARE",
  PROGRESS: "PROGRESS",
  PROTOCOL_DISCHARGE: "PROTOCOL_DISCHARGE",
  PROTOCOL_MONTHLY: "PROTOCOL_MONTHLY",
  PROTOCOL_QUARTERLY: "PROTOCOL_QUARTERLY",
  PROTOCOL_WEEKLY: "PROTOCOL_WEEKLY",
  PROTOCOL_WEEKLY_W0: "PROTOCOL_WEEKLY_W0",
  PROTOCOL_WEEKLY_W10: "PROTOCOL_WEEKLY_W10",
  PROTOCOL_WEEKLY_W11: "PROTOCOL_WEEKLY_W11",
  PROTOCOL_WEEKLY_W11_AND_W12: "PROTOCOL_WEEKLY_W11_AND_W12",
  PROTOCOL_WEEKLY_W12: "PROTOCOL_WEEKLY_W12",
  PROTOCOL_WEEKLY_W13: "PROTOCOL_WEEKLY_W13",
  PROTOCOL_WEEKLY_W14: "PROTOCOL_WEEKLY_W14",
  PROTOCOL_WEEKLY_W15: "PROTOCOL_WEEKLY_W15",
  PROTOCOL_WEEKLY_W16: "PROTOCOL_WEEKLY_W16",
  PROTOCOL_WEEKLY_W17: "PROTOCOL_WEEKLY_W17",
  PROTOCOL_WEEKLY_W18: "PROTOCOL_WEEKLY_W18",
  PROTOCOL_WEEKLY_W19: "PROTOCOL_WEEKLY_W19",
  PROTOCOL_WEEKLY_W20: "PROTOCOL_WEEKLY_W20",
  PROTOCOL_WEEKLY_W2: "PROTOCOL_WEEKLY_W2",
  PROTOCOL_WEEKLY_W3: "PROTOCOL_WEEKLY_W3",
  PROTOCOL_WEEKLY_W4: "PROTOCOL_WEEKLY_W4",
  PROTOCOL_WEEKLY_W5: "PROTOCOL_WEEKLY_W5",
  PROTOCOL_WEEKLY_W6: "PROTOCOL_WEEKLY_W6",
  PROTOCOL_WEEKLY_W7: "PROTOCOL_WEEKLY_W7",
  PROTOCOL_WEEKLY_W7_AND_W8: "PROTOCOL_WEEKLY_W7_AND_W8",
  PROTOCOL_WEEKLY_W8: "PROTOCOL_WEEKLY_W8",
  PROTOCOL_WEEKLY_W9: "PROTOCOL_WEEKLY_W9",
  PROTOCOL_WEEKLY_W9_AND_W10: "PROTOCOL_WEEKLY_W9_AND_W10",
  RE_EVALUATION: "RE_EVALUATION",
  RTM_ADDITIONAL: "RTM_ADDITIONAL",
  RTM_INITIAL: "RTM_INITIAL",
  SUPPLEMENTAL: "SUPPLEMENTAL",
  TREATMENT: "TREATMENT",
  TREATMENT_AND_PROGRESS: "TREATMENT_AND_PROGRESS",
  UNLOCKING_THERAPIST: "UNLOCKING_THERAPIST",
  PHYSICAL_VISIT_NEW: "PHYSICAL_VISIT_NEW",
  PHYSICAL_VISIT_RETURN: "PHYSICAL_VISIT_RETURN",
  DEFAULT: "DEFAULT",
  INITIAL_HYBRID: "INITIAL_HYBRID",
  CHAT_HYBRID: "CHAT_HYBRID",
} as const;

export type MedicalNoteType = typeof MedicalNoteTypes[keyof typeof MedicalNoteTypes];

// Sections types that contain special logic
export const MedicalNoteSectionTypes = {
  EXCLUSION: "exclusion",
  TOP: "top",
  SUGGESTIONS: "suggestions",
} as const;

type MedicalNoteSectionType = typeof MedicalNoteSectionTypes[keyof typeof MedicalNoteSectionTypes];

export type ForcedOptionalType = "isExclusionCode";

export interface MedicalNoteSectionConfig {
  fields?: MedicalNoteFieldConfig[];
  name: string;
  forcedOptional?: ForcedOptionalType; // Allows override of Field level required property for Section based on condition
  type?: MedicalNoteSectionType;
}

// Input types. MUITextField is the default when not defined
export const MedicalNoteFieldTypes = {
  BMI: "bmi", // MUITextField
  CHECKBOX: "checkbox", // MUICheckBox
  CONFIRMED_INSURANCE_AND_IDENTITY_CHECKBOX: "confirmed-insurance-and-identity-checkbox", // special MUICheckbox trigging 2 values
  CPT_MULTISELECT_DROPDOWN: "cpt-multiselect-dropdown", // MUIDropdown for selecting multiple CPT codes, used in US market
  DAYS_INPUT: "days-input", // MUIMaskedInput for duration in days
  COMMUNICATION_TYPE_DROPDOWN: "communication-type-dropdown", // MUIDropdown with fixed options
  FREQUENCY_READONLY: "frequency-readonly", // readonly MUITextField with hard coded value
  FUNCTIONALITY_INPUT: "functionality-input", // MUITextField, numeric, min-max 0-35
  ICD_DROPDOWN: "icd-dropdown", // custom designed MUIDropdown for ICD codes, options from BE api
  MULTISELECT_DROPDOWN: "multiselect-dropdown", // MUIDropdown for selecting multiple options from an array of options in the config
  PAIN_DROPDOWN: "pain-dropdown", // MUIDropdown, options 0 - 10
  FEELING_INPUT: "feeling-input", // MUITextField, numeric, min-max 0-100
  RADIO: "radio", // MUIRadioGroup
  STATIC_TEXT: "static-text", // Used for System Suggestions rendering text without input
  CHAPERONE_TEXT: "chaperone-text", // Used in UK market
  DISCHARGE_FURTHER_ACTION: "discharge-further-action", // Used in UK market
  UK_PLAN_TEXT: "uk-plan-text", // Used in UK market
  QUICK_TEXT: "quick-text", // Used for text fields with quick text adornment button
  CHECKBOX_CONFIRMED_DIAGNOSIS: "checkbox-confirmed-diagnosis", // MUICheckBox for confirming diagnosis
  CHECKBOX_CONFIRMED_DATE: "checkbox-confirmed-date", // MUICheckBox for confirming date used in Kickoff notes in SE/UK
  KVA_CODES_MULTISELECT_DROPDOWN: "kva-codes-multiselect-dropdown", // MUIDropdown for selecting multiple KVÅ codes, used in SE
  PREVIOUS_SURVEYS_TEXT: "previous-surveys-text", // used for Frösjö to show a different label
  PREVIOUS_SURVEYS_DROPDOWN: "previous-surveys-dropdown", // MUIDropdown related to field previous_surveys, used for Frösjö
  PREVIOUS_SURVEYS_QUICK_TEXT: "previous-surveys-quick-text", // used for Frösjö to be conditionally rendered
  PREVIOUS_SURVEYS_MULTISELECT: "previous-surveys-multiselect", // used for Frösjö to be conditionally rendered
} as const;

export type MedicalNoteFieldType = typeof MedicalNoteFieldTypes[keyof typeof MedicalNoteFieldTypes];

export const MedicalNoteReadonlyFieldTypes: MedicalNoteFieldType[] = [
  MedicalNoteFieldTypes.FREQUENCY_READONLY,
  MedicalNoteFieldTypes.STATIC_TEXT,
  MedicalNoteFieldTypes.CHECKBOX_CONFIRMED_DATE, // unregistered active field
];

export const MedicalNoteHalfwidthFieldTypes: MedicalNoteFieldType[] = [
  MedicalNoteFieldTypes.PAIN_DROPDOWN,
  MedicalNoteFieldTypes.FUNCTIONALITY_INPUT,
  MedicalNoteFieldTypes.FREQUENCY_READONLY,
  MedicalNoteFieldTypes.DAYS_INPUT,
  MedicalNoteFieldTypes.BMI,
];

export interface MedicalNoteFieldConfig {
  helptext?: boolean;
  name: CareEventFieldPath | "confirmed_insurance_and_identity";
  options?: string[]; // Option values, translation keys for radio groups
  required?: boolean;
  subsection?: string;
  type?: MedicalNoteFieldType; // When left undefined defaults to MUITextField
  forceLabel?: boolean; // option to overwrite the default field's label
}

export type MedicalNoteTypesConfig = { [K in MedicalNoteType]?: Array<MedicalNoteSectionConfig> | MedicalNoteType };

export type MedicalNoteConfigVersion = {
  validFrom: DateString;
  config: MedicalNoteTypesConfig;
};
