import type React from "react";
import { useState } from "react";

import { Popover } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { usePinnedMessagesContext } from "contexts/PinnedMessagesContext";
import type { PinnedMessage } from "routes/messages/types";

interface Props {
  commentCallBack?: () => void;
  message?: PinnedMessage;
}

const MarkAsUnread: React.VFC<Props> = ({ commentCallBack, message }) => {
  const { t } = useTranslation();
  const { pinMessage } = usePinnedMessagesContext();

  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);

  const handleClick = (event: React.MouseEvent) => {
    if (event.currentTarget !== null) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Container comment={!!commentCallBack}>
      <MenuButton data-testid="MarkAsUnread-menu-button" onClick={handleClick} $isOpen={open}>
        •••
      </MenuButton>
      {anchorEl && (
        <Menu
          open={open}
          anchorEl={anchorEl as Element}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            data-testid="MarkAsUnread-button"
            onClick={() => {
              if (message) {
                pinMessage({
                  body: message?.body,
                  created_at: message?.created_at,
                  id: message?.id,
                  peerId: message?.peerId,
                });
              }

              if (commentCallBack) {
                commentCallBack();
              }

              handleClose();
            }}
          >
            {t("button.mark_as_unread")}
          </MenuItem>
        </Menu>
      )}
    </Container>
  );
};

export default MarkAsUnread;

export const MenuButton = styled.div<{ $isOpen: boolean }>`
  position: relative;
  min-height: 28px;
  min-width: 28px;
  height: 28px;
  line-height: 28px;
  width: 28px;
  color: ${props => props.theme.colors.primary.base};
  border-radius: 50%;
  cursor: pointer;
  text-align: center;

  ${props => props.theme.belowMobileBreakpoint} {
    opacity: 1;
  }
`;

const Menu = styled(Popover)`
  white-space: nowrap;
  & > div {
    background-color: ${props => props.theme.colors.white};
    border-radius: 8px;
    box-shadow: 0 2px 7px rgba(141, 155, 173, 0.2);
    overflow: visible;

    &:after {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background: white;
      top: 0;
      right: 8px;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      box-shadow: -2px -2px 7px rgb(141 155 173 / 20%);
    }
  }
`;

const MenuItem = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 12px;
  color: ${props => props.theme.colors.primary.base};
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`;

const commentStyles = css`
  top: auto;
  bottom: 20px;
`;

const Container = styled.div<{ comment?: boolean }>`
  display: flex;
  align-items: center;

  ${props => props.comment && commentStyles}
`;
