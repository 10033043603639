import type React from "react";
import { useEffect, useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useTranslation } from "react-i18next";
import type { DefaultTheme } from "styled-components";
import styled from "styled-components";

import { DropdownIcon } from "assets";

import { SectionHelp } from ".";

interface Props {
  readonly collapsible?: boolean;
  readonly children: React.ReactNode;
  readonly helpContent?: string | React.ReactNode;
  readonly dataTestId?: string;
  readonly disabled?: boolean;
  readonly collapsedInitially?: boolean;
  readonly backgroundColor?: "blue" | "white" | "default";
  readonly label?: string;
  readonly optional?: boolean;
  readonly readOnly?: boolean;
}

const Section: React.VFC<Props> = ({
  backgroundColor = "default",
  collapsible = true,
  children,
  dataTestId,
  disabled = false,
  collapsedInitially = false,
  helpContent,
  label,
  optional,
  readOnly,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(!collapsedInitially);
  const toggle = () => {
    if (!disabled && collapsible) setExpanded(!expanded);
  };

  useEffect(() => {
    if (disabled || !collapsible) setExpanded(true);
  }, [disabled]);
  return (
    <StyledAccordion
      expanded={expanded}
      onChange={toggle}
      data-testid={dataTestId}
      $backgroundColor={backgroundColor}
      readOnly={readOnly}
    >
      {label && (
        <StyledAccordionSummary $collapsible={collapsible} disabled={disabled} expandIcon={<DropdownIcon />}>
          <Label>
            {label}
            {optional && <OptionalText> ({t("patients.notes.optional_section")})</OptionalText>}
          </Label>
          {collapsible && helpContent && label && <SectionHelp label={label}>{helpContent}</SectionHelp>}
        </StyledAccordionSummary>
      )}
      <StyledAccordionDetails>
        <Container>{children}</Container>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

const getBackgroundColor = (color: string, theme: DefaultTheme) => {
  switch (color) {
    case "blue":
      return theme.colors.pastels.blue;
    case "white":
      return theme.colors.greys.light7;
    case "default":
    default:
      return "transparent";
  }
};

const StyledAccordion = styled(Accordion)<{ $backgroundColor: string; readOnly?: boolean }>`
  &.MuiAccordion-root {
    background-color: ${({ $backgroundColor, theme }) => getBackgroundColor($backgroundColor, theme)};
    box-shadow: none;
    border-bottom: 1px solid ${props => (props.readOnly ? "transparent" : props.theme.colors.redesign.db30)};
    &:before {
      content: none;
    }

    &.Mui-expanded {
      margin: 0;
    }
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)<{ disabled: boolean; $collapsible: boolean }>`
  &.MuiAccordionSummary-root {
    padding-left: ${props => props.theme.spacing.S_20};
    &.MuiButtonBase-root,
    &.Mui-expanded:hover,
    .MuiButtonBase-root {
      cursor: ${({ disabled }) => disabled && "not-allowed"};
      cursor: ${({ $collapsible }) => !$collapsible && "default"};
    }
    &.Mui-disabled {
      opacity: 1;
      pointer-events: inherit;
      span {
        opacity: 0.38;
      }
    }
    &.Mui-expanded {
      min-height: 60px;
    }

    .MuiAccordionSummary-expandIcon {
      display: ${({ $collapsible }) => !$collapsible && "none"};
    }
  }
  .MuiAccordionSummary-content {
    align-items: center;
    min-height: 28px;
    margin: 16px 0;
    &.Mui-expanded {
      margin: 16px 0;
    }
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding-left: ${props => props.theme.spacing.S_20};
    padding-right: ${props => props.theme.spacing.S_20};

    ${props => props.theme.belowMobileBreakpoint} {
      padding-left: ${props => props.theme.spacing.S_10};
      padding-right: ${props => props.theme.spacing.S_10};
    }
  }
`;

const Container = styled.div`
  padding-right: 100px;
  width: 100%;

  ${props => props.theme.belowBreakpoint} {
    padding-right: 0;
  }
`;

const Label = styled.span`
  ${({ theme }) => theme.font.section_title};
  margin-right: ${({ theme }) => theme.spacing.S_10};
`;

const OptionalText = styled.span`
  text-transform: none;
  letter-spacing: 0.5px;
  font-weight: 300;
  color: ${props => props.theme.colors.redesign.db90};
`;

export { Section };
