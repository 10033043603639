import type React from "react";
import { useCallback, useEffect, useState } from "react";

import { Popover } from "@mui/material";
import styled from "styled-components";

import type { Props as TooltipProps } from "shared/molecules/Tooltip";
import Tooltip from "shared/molecules/Tooltip";

import { getElementPath } from "../helpers";

import DropdownOption from "./DropdownButtonOption";
import DropDownOptions from "./DropdownButtonOptions";

interface DropDownProps {
  id: string;
  children?: React.ReactNode;
  options: {
    label?: string;
    children?: React.ReactNode;
    onClick?: () => void;
    dataTestId?: string;
    disabled?: boolean;
  }[];
  tooltips?: TooltipProps[];
  disabled?: boolean;
  onClick?: () => void;
  onDisabled?: () => void;
  small?: boolean;
  dataTestId?: string;
  first?: boolean;
  last?: boolean;
  customDropdownStyling?: string;
  onClose?: () => void;
  padding?: string;
  className?: string;
  border?: boolean;
  dropdownOrigin?: "left" | "right";
  dropdownListMinWidth?: string;
}

const DropDownButton: React.VFC<DropDownProps> = ({
  id,
  options,
  tooltips = [],
  children,
  disabled = false,
  onClick = () => undefined,
  onDisabled = () => undefined,
  small,
  dataTestId,
  first,
  last,
  customDropdownStyling,
  onClose,
  padding,
  className,
  border = true,
  dropdownOrigin,
  dropdownListMinWidth,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const isDropdownOpen = Boolean(anchorEl);

  useEffect(() => {
    if (onClose && !isDropdownOpen) {
      onClose();
    }
  }, [isDropdownOpen]);

  const clickAway = useCallback(
    e => {
      e.stopPropagation();
      const thisElement = document.getElementById(id);
      const isInternal = !!getElementPath(e.target).find(
        (item: HTMLElement | Document | (Window & typeof globalThis)) => item === thisElement
      );
      if (disabled && !isInternal) {
        tooltips.forEach(tooltip => {
          if (tooltip.isOpen && tooltip.requestClose) {
            tooltip.requestClose();
          }
        });
      }
    },
    [isDropdownOpen]
  );

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.currentTarget !== null && !disabled) {
      setAnchorEl(event.currentTarget);
      onClick();
    } else {
      onDisabled();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setListener = () => document.addEventListener("click", clickAway);
  const unsetListener = () => document.removeEventListener("click", clickAway);

  useEffect(() => {
    setListener();
    return () => {
      unsetListener();
    };
  }, [clickAway]);

  return (
    <DropdownButtonWrapper disabled={disabled} small={small} data-testid={dataTestId} className={className}>
      <DropdownButton
        id="DropdownButton"
        data-testid="DropdownButtonWrapper"
        onClick={handleClick}
        small={small}
        isOpen={isDropdownOpen}
        first={first}
        last={last}
        customdropdownstyling={customDropdownStyling}
        $disabled={disabled}
        $padding={padding}
      >
        {children}
      </DropdownButton>
      <Popover
        data-testid="popover"
        open={isDropdownOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: dropdownOrigin ?? "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: dropdownOrigin ?? "right",
        }}
      >
        <DropDownOptions
          parentId={id}
          isOpen
          small={small}
          items={options.length}
          dropdownListMinWidth={dropdownListMinWidth}
        >
          {options.map((option, idx) => {
            const key = `${id}-dropdown-option-${idx}`;
            return (
              <DropdownOption
                key={key}
                row={idx}
                onClick={() => {
                  setAnchorEl(null);
                  if (option.onClick !== undefined) {
                    option.onClick();
                  }
                }}
                dataTestId={option.dataTestId}
                disabled={option.disabled}
                border={border}
              >
                {option.children || option.label}
              </DropdownOption>
            );
          })}
        </DropDownOptions>
      </Popover>
      <>
        {tooltips.map((tooltip, idx) => {
          const key = `dropdownbutton_${id}_tooltip_${idx}`;
          return (
            <Tooltip key={key} {...tooltip}>
              {tooltip.children}
            </Tooltip>
          );
        })}
      </>
    </DropdownButtonWrapper>
  );
};

export default DropDownButton;

const DropdownButtonWrapper = styled.button<{ $justifyContent?: string; small?: boolean }>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  flex-direction: row;
  justify-content: ${props => props.$justifyContent || "center"};
  align-items: stretch;
  outline: none;
  flex: 1;
  width: 100%;
  padding: 0px;
  background: transparent;
  border: none;
  border-right: 1px solid ${props => props.theme.colors.greys.silver};
  font-size: 18px;
  user-select: none;
  font-family: "Roboto", sans-serif;
  stroke: none;
  ${props => (props.small ? `max-width: 70px;` : ``)}
  ${props =>
    props.disabled &&
    ` color: ${props.theme.colors.greys.pinkish};
      stroke: ${props.theme.colors.greys.pinkish};
  `};

  :last-of-type {
    border-right: none;
  }
  > svg {
    margin-right: ${props => (props.small ? `0px` : props.theme.spacing.S_10)};
  }
  li & {
    justify-content: start;
    padding: 0;
  }
`;

const DropdownButton = styled.div<{
  $disabled?: boolean;
  small?: boolean;
  isOpen?: boolean;
  first?: boolean;
  last?: boolean;
  customdropdownstyling?: string;
  $padding?: string;
}>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  outline: none;
  flex: 1;
  padding: ${props => props.$padding};
  ${props => props.customdropdownstyling === "custom-position" && `padding: 12px;`}
  background: ${props => (props.isOpen ? props.theme.colors.redesign.b30 : "transparent")};
  color: ${props => props.theme.colors.primary.base};
  border: none;
  font-family: "Roboto", sans-serif;
  user-select: none;
  ${props => (props.first ? `border-radius: 0 0 0 ${props.theme.borderRadius.basic};` : "")}
  ${props => (props.last ? `border-radius: 0 0 ${props.theme.borderRadius.basic} 0;` : "")}
  
  :last-of-type {
    border-right: none;
  }
  :hover {
    cursor: ${props => !props.$disabled && "pointer"};
    background: ${props => !props.$disabled && props.theme.colors.redesign.b30};
  }
  > svg {
    margin-right: ${props =>
      props.small || props.customdropdownstyling === "custom-position" ? `0px` : props.theme.spacing.S_10};
  }
  li & {
    justify-content: start;
    padding: 0;
  }
`;
