import type React from "react";

import type { CeStructureType } from "api/models/PatientProfile";
import { HybridCareBigIcon, HybridCareSmallIcon } from "assets";

interface Props {
  size: "small" | "big";
  ceStructure: CeStructureType | undefined;
  margin?: string;
}

export const HybridCareIcon: React.VFC<Props> = ({ ceStructure, size, margin }) => {
  if (ceStructure === "hybrid") {
    if (size === "big") return <HybridCareBigIcon data-testid="hybrid_care_big_icon" style={{ margin }} />;
    if (size === "small")
      return <HybridCareSmallIcon data-testid="hybrid_care_small_icon" style={{ minWidth: 20, margin }} />;
  }
  return null;
};
