import type React from "react";

import { ListItemIcon, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { MarketLoginSelectorFR, MarketLoginSelectorSE, MarketLoginSelectorUK } from "assets";
import { getAppMarket } from "utils/market";
import { setStorageValue } from "utils/storage";

type MarketLogin = {
  icon: JSX.Element;
  label: "login.market_selector.se" | "login.market_selector.fr" | "login.market_selector.uk";
  language: string;
  type: "email" | "bankid";
  value: "FR" | "SE" | "UK";
};

const marketLogins: MarketLogin[] = [
  {
    icon: <MarketLoginSelectorSE />,
    label: "login.market_selector.se",
    language: "sv-SE",
    type: "bankid",
    value: "SE",
  },
  {
    icon: <MarketLoginSelectorFR />,
    label: "login.market_selector.fr",
    language: "fr-FR",
    type: "email",
    value: "FR",
  },
  {
    icon: <MarketLoginSelectorUK />,
    label: "login.market_selector.uk",
    language: "en-GB",
    type: "email",
    value: "UK",
  },
];

export const MarketLoginSelector: React.VFC = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  return (
    <StyledSelect
      disableUnderline
      onChange={event => {
        const market = marketLogins.find(({ value }) => {
          return value === event.target.value;
        });

        i18n.changeLanguage(market?.language);
        setStorageValue("language", market?.language as string);
        navigate(`/login/${market?.type}`);
      }}
      defaultValue={getAppMarket()}
      variant="standard"
    >
      {marketLogins.map(({ icon, label, value }) => (
        <MenuItem key={value} value={value}>
          <StyledListItemIcon>{icon}</StyledListItemIcon>
          <StyledLabel>{t(label)}</StyledLabel>
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  margin-top: 40px;

  .MuiSelect-select {
    display: flex;
    align-items: center;
  }

  ${props => props.theme.belowBreakpoint} {
    margin-top: 20px;
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    display: flex;
    min-width: auto;
    margin-right: 8px;
  }
`;

const StyledLabel = styled.div`
  margin-right: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.44px;
`;
