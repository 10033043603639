import type React from "react";

import { LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Container, Content, Header, Title } from "./styles";

interface Props {
  enrollments?: number;
  enrolledPatientsGoal?: number;
}

export const EnrolledPatients: React.VFC<Props> = ({ enrollments, enrolledPatientsGoal = 10 }) => {
  const { t } = useTranslation();

  const calculatePercentage = (enrolledPatients?: number) => {
    if (!enrolledPatients) {
      return 0;
    }

    const percentage = (enrolledPatients / enrolledPatientsGoal) * 100;
    if (percentage > 100) {
      return 100;
    }
    return percentage;
  };

  return (
    <StyledContainer>
      <Header>{t("earnings_widget.enrolled_patients.header")}</Header>
      <Content>
        <Title>{t("earnings_widget.enrolled_patients.title")}</Title>
        <Progress>
          <LinearProgress variant="determinate" value={calculatePercentage(enrollments)} />
          <Text>
            {enrollments}/{enrolledPatientsGoal}
          </Text>
        </Progress>
        <CommissionLevels>
          <Text>{t("earnings_widget.enrolled_patients.commission_level_1")}</Text>
          <CommissionNextLevel>{t("earnings_widget.enrolled_patients.commission_level_2")}</CommissionNextLevel>
        </CommissionLevels>
      </Content>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  margin-bottom: 20px;
`;

const Progress = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;

  .MuiLinearProgress-root {
    height: 15px;
    width: 100%;
    border-radius: 15px;
  }

  .MuiLinearProgress-colorPrimary {
    background-color: #e5e7eb;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: ${({ theme }) => theme.colors.redesign.g100};
    border-radius: 15px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const CommissionLevels = styled.div`
  display: flex;
`;

const CommissionNextLevel = styled(Text)`
  margin-left: auto;
  margin-right: 54px;
`;
