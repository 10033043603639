import { useMutation } from "react-query";

import type { PostTreatmentActivityLogResponse, PutTreatmentActivityLogArgs } from "api/schemas/TreatmentActivityLog";
import useApiClient from "api/useApiClient";

export const usePutTreatmentActivityLog = () => {
  const client = useApiClient();

  return useMutation<PostTreatmentActivityLogResponse, Error, PutTreatmentActivityLogArgs>(args =>
    client.putTreatmentActivityLog(args)
  );
};
