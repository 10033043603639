import type React from "react";
import { useEffect, useState } from "react";

import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useNavigate, useRoutes } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import invariant from "ts-invariant";

import useGetManagerServices from "api/hooks/useGetManagerServices";
import { usePostPatientOnboardingComplete } from "api/hooks/useManagerBooking";
import { usePostAppointmentManager } from "api/hooks/usePostAppointmentManager";
import type { HcpServiceManager, PurposeType } from "api/schemas/HcpService";
import type { PatientResponse } from "api/schemas/ManagerBooking";
import { BackIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";

import type { Option } from "../helpers";
import { generateOptions } from "../helpers";

import { Wrapper } from "./misc";
import { Confirmation } from "./screens/Confirmation";
import { CreatePatient } from "./screens/CreatePatient";
import { HealthQuestionnaire } from "./screens/HealthQuestionnaire";
import { SelectContactDetails } from "./screens/SelectContactDetails";
import { SelectDateAndPersonnel } from "./screens/SelectDateAndPersonnel";
import { SelectMode } from "./screens/SelectMode";
import { SelectService } from "./screens/SelectService";
import { Summary } from "./screens/Summary";

export const BOOKING_SCREENS = {
  CREATE_PATIENT: "create_patient",
  SELECT_MODE: "select_mode",
  SELECT_SERVICE: "select_service",
  HEALTH_QUESTIONNAIRE: "health_questionnaire",
  SELECT_DATE_AND_PERSONNEL: "select_date_and_personnel",
  SELECT_CONTACT_DETAILS: "select_contact_details",
  SUMMARY: "summary",
  CONFIRMATION: "confirmation",
} as const;

function getDefaultMedium(mode: string | undefined) {
  switch (mode) {
    case "digital":
      return "video";
    case "physical":
      return "face_to_face";
    default:
      return "";
  }
}

export const AdminBookingForm: React.VFC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = matchPath(`/calendar/book/${BOOKING_SCREENS.CREATE_PATIENT}`, location.pathname);
  const { t } = useTranslation();
  const theme = useTheme();
  const { profile } = useProfileContext();

  const [progress, setProgress] = useState(0);
  const [personalNumber, setPersonalNumber] = useState<string | null>(null);
  const [patient, setPatient] = useState<PatientResponse | undefined>(undefined);
  const [mode, setMode] = useState<string>("");
  const [purpose] = useState<PurposeType | null>("kick_off");
  const [service, setService] = useState<HcpServiceManager | undefined>(undefined);
  const [personnel, setPersonnel] = useState<number | null>(null);
  const [personnelName, setPersonnelName] = useState<string | null>(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState<string | null>(null);

  const [filteredServices, setFilteredServices] = useState<HcpServiceManager[]>([]);
  const [serviceOptions, setServiceOptions] = useState<Option[]>([]);

  invariant(profile);

  const { data: services = [] } = useGetManagerServices(profile.id);
  const postAppointmentManagerMutation = usePostAppointmentManager();
  const postPatientOnboardingComplete = usePostPatientOnboardingComplete();

  useEffect(() => {
    setFilteredServices(services.filter(s => s.mode === mode && s.purpose === purpose));
  }, [mode, purpose, services]);

  useEffect(() => {
    setServiceOptions(generateOptions({ array: filteredServices, labelKey: "name", valueKey: "id" }));
  }, [filteredServices]);

  useEffect(() => {
    const path = location.pathname.split("/")[3];
    switch (path) {
      case BOOKING_SCREENS.CREATE_PATIENT:
        setProgress(0);
        break;
      case BOOKING_SCREENS.SELECT_MODE:
        setProgress(12.5);
        break;
      case BOOKING_SCREENS.SELECT_SERVICE:
        setProgress(25);
        break;
      case BOOKING_SCREENS.HEALTH_QUESTIONNAIRE:
        setProgress(37.5);
        break;
      case BOOKING_SCREENS.SELECT_DATE_AND_PERSONNEL:
        setProgress(50);
        break;
      case BOOKING_SCREENS.SELECT_CONTACT_DETAILS:
        setProgress(62.5);
        break;
      case BOOKING_SCREENS.SUMMARY:
        setProgress(75);
        break;
      case BOOKING_SCREENS.CONFIRMATION:
        setProgress(100);
        break;
      default:
        break;
    }
  }, [location]);

  const bookAppointment = () => {
    if (patient && personnel && service) {
      const data = {
        end_time: endTime,
        health_care_professional_id: personnel,
        health_care_provider_service_id: service.id,
        // FIXME: Check if it's ok to decide mediun.
        medium: getDefaultMedium(mode),
        patient_id: patient.id,
        start_time: startTime,
      };
      postAppointmentManagerMutation.mutateAsync(
        { data, managerId: profile.id },
        {
          onError: () => {
            setError(t("errors.generic"));
          },
          onSuccess: () => {
            postPatientOnboardingComplete.mutateAsync({ managerId: profile.id, patientId: patient.id });
            setError(null);
          },
        }
      );
    }
  };

  const routes = useRoutes([
    {
      path: BOOKING_SCREENS.CREATE_PATIENT,
      element: (
        <Wrapper title={t("booking.manager_booking.screen_titles.create_patient")}>
          <CreatePatient setPatient={setPatient} setPersonalNumber={setPersonalNumber} />
        </Wrapper>
      ),
    },
    {
      path: BOOKING_SCREENS.SELECT_MODE,
      element: (
        <Wrapper title={t("booking.manager_booking.screen_titles.select_mode")}>
          <SelectMode setMode={setMode} />
        </Wrapper>
      ),
    },
    {
      path: BOOKING_SCREENS.SELECT_SERVICE,
      element: (
        <Wrapper backButton title={t("booking.manager_booking.screen_titles.select_service")}>
          <SelectService
            newPatient={patient?.state === "in_first_health_form"}
            services={filteredServices}
            serviceOptions={serviceOptions}
            setService={setService}
          />
        </Wrapper>
      ),
    },
    {
      path: `${BOOKING_SCREENS.HEALTH_QUESTIONNAIRE}/*`,
      element: (
        <Wrapper backButton title={t("booking.manager_booking.screen_titles.health_questionnaire")}>
          <HealthQuestionnaire patientId={patient?.id} />
        </Wrapper>
      ),
    },
    {
      path: BOOKING_SCREENS.SELECT_DATE_AND_PERSONNEL,
      element: (
        <Wrapper backButton title={t("booking.manager_booking.screen_titles.select_date_and_personnel")}>
          <SelectDateAndPersonnel
            service={service}
            personnel={personnel}
            startTime={startTime}
            setPersonnel={setPersonnel}
            setPersonnelName={setPersonnelName}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
          />
        </Wrapper>
      ),
    },
    {
      path: BOOKING_SCREENS.SELECT_CONTACT_DETAILS,
      element: (
        <Wrapper backButton title={t("booking.manager_booking.screen_titles.select_contact_details")}>
          <SelectContactDetails
            patientId={patient?.id}
            email={email}
            phone={phone}
            setEmail={setEmail}
            setPhone={setPhone}
          />
        </Wrapper>
      ),
    },
    {
      path: BOOKING_SCREENS.SUMMARY,
      element: (
        <Wrapper backButton title={t("booking.manager_booking.screen_titles.summary")}>
          <Summary
            email={email}
            endTime={endTime}
            mode={mode}
            personal_number={personalNumber}
            personnel={personnelName}
            phone={phone}
            service={service}
            startTime={startTime}
          />
        </Wrapper>
      ),
    },
    {
      path: BOOKING_SCREENS.CONFIRMATION,
      element: (
        <Wrapper
          title={
            error ? (
              <>
                {t("booking.manager_booking.screen_titles.confirmation_error")}{" "}
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  color={theme.colors.redesign.r100}
                  style={{ height: "18px", width: "18px" }}
                />
              </>
            ) : (
              <>
                {t("booking.manager_booking.screen_titles.confirmation_success")}{" "}
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color={theme.colors.redesign.g100}
                  style={{ height: "18px", width: "18px" }}
                />
              </>
            )
          }
        >
          <Confirmation error={error} bookAppointment={bookAppointment} />
        </Wrapper>
      ),
    },
  ]);

  return (
    <Container>
      <Header>
        <BackButtonHeader onClick={() => navigate(-1)} $visible={!match}>
          <BackIcon />
        </BackButtonHeader>
        <span>{t("booking.manager_booking.title")}</span>
        <div />
      </Header>
      <LinearProgress variant="determinate" value={progress} style={{ marginBottom: "16px" }} />

      {routes}
    </Container>
  );
};

const Container = styled.div`
  min-width: 400px;
  background: #fff;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 16px 16px;
  color: ${props => props.theme.colors.primary.base};
  font-size: 18px;
  font-weight: 500;
`;

const BackButtonHeader = styled.div<{ $visible: boolean }>`
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
  cursor: pointer;
`;
